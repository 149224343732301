import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import ReactGA from "react-ga";

//importing routesPages

import LandingPage from "../pages/Landing/LandingPage";
import ProductsNewPage from "../pages/Products/ProductsNewpage";
import AboutUsNewPage from "../pages/AboutUs/AboutUsNewPage";
import CareersNewPage from "../pages/Careers/CareersNewPage";
import InvestorsPage from "../pages/Investors/InvestorsPage";
import BlogsPage from "../pages/Blogs/BlogsPage";
import JobDescriptionPage from "../pages/JobDescription/JobDescriptionPage";
import AllJobsListPage from "../pages/AllJobsList/AllJobsListPage";
import ApplyJobPage from "../pages/ApplyJob/ApplyJobPage";
import ErrorPage from "../pages/Error/ErrorPage";
import kPayPage from "../pages/kPay/kPayPage";
import TermAndCondition from "../pages/TermAndCondition/TermAndCondition";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";

//This contains all the routes in the website
const routes = [
  {
    title: "Home",
    path: "/",
    component: LandingPage,
  },
  {
    title: "Products",
    path: "/products",
    component: ProductsNewPage,
  },
  {
    title: "Kpay",
    path: "/products/kpay",
    component: kPayPage,
  },
  {
    title: "About",
    path: "/about",
    component: AboutUsNewPage,
  },
  {
    title: "Careers",
    path: "/careers",
    component: CareersNewPage,
  },
  {
    title: "Investors",
    path: "/investors",
    exact: true,
    component: InvestorsPage,
  },

  {
    title: "Blog",
    path: "/blogs",
    exact: true,
    component: BlogsPage,
  },

  {
    title: "Apply Job",
    path: "/careers/apply",
    exact: true,
    component: ApplyJobPage,
  },
  {
    title: "All Jobs",
    path: "/careers/alljobs",
    exact: true,
    component: AllJobsListPage,
  },
  {
    title: "Jobs",
    path: "/careers/:jobId",
    exact: true,
    component: JobDescriptionPage,
  },
  {
    title: "Term And Conditions",
    path: "/TermAndCondition",
    component: TermAndCondition,
  },
  {
    title: "Privacy Policy",
    path: "/PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    title: "Error",
    path: "/**/",
    exact: true,
    component: ErrorPage,
  },
];

ReactGA.initialize("UA-212828207-1");

const Routes = () => {
  const location = useLocation();

  useEffect(() => {
    // This line will trigger on a route change
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const routeComponents = routes.map((route) => (
    <Route
      exact={true}
      path={route.path}
      component={route.component}
      key={route.title}
    />
  ));

  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.key}>
        {routeComponents}
      </Switch>
    </AnimatePresence>
  );
};

export default Routes;
