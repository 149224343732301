import styled, {css} from "styled-components";


export const Wrapper = styled.div`
  ${(props) => props.theme.wrapper}
`;

export const StyledSection = styled.section``;

export const ImageWrapper = styled.div`
  margin-top: 200px;
  margin-bottom: 48px;
  text-align: center;
`;

export const ErrorText = styled.h2`
  text-align: center;
  font-family: ${(props) => props.theme.fonts.Lato};
  font-size: 32px;
  line-height: 48px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 20px;
`;

export const HeaderText = styled.h4`
  text-align: left;
  font-family: ${(props) => props.theme.fonts.Lato};
  font-size: 22px;
  line-height: 48px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const ParaText = styled.text`
  text-align: justify;
  font-family: ${(props) => props.theme.fonts.Lato};
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 10px;
`;

export const BulletText = styled.text`
  text-align: center;
  font-size: 5px;
  font-weight: 100;
  margin-right: 5px;
`;

export const LastText = styled.p`
  text-align: justify;
  font-family: ${(props) => props.theme.fonts.Lato};
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 30px;
`;

export const MailText = styled.a`
  font-family: ${(props) => props.theme.fonts.Lato};
  margin-left: 5px;
`;

export const StrongText = styled.text`
  font-family: ${(props) => props.theme.fonts.Lato};
  font-size: 15px;
  font-weight: 1000;
  line-height: 20px;
  margin-bottom: 10px;
`;
