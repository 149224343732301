import styled from "styled-components";

import { HashLink } from "react-router-hash-link";

export const StyledFooter = styled.footer`
  background-color: ${(props) => props.theme.colors.appDark900};
`;

export const Wrapper = styled.div`
  ${(props) => props.theme.wrapper};
`;

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 168px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0px 48px;
    padding-top: 168px;
  }
`;

export const NavsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30%;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const FooterDivider = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.colors.appWhite};
  margin-top: 28px;
  margin-bottom: 32px;
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    padding: 0px 48px;

    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const BottomLeftWrapper = styled.div`
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 168px;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    padding-bottom: 32px;
  }
`;

export const Logo = styled.img`
  /* text-align: left; */
`;

export const LogoText = styled.h4`
  font-family: ${(props) => props.theme.fonts.Lato};
  color: ${(props) => props.theme.colors.appWhite};
  font-size: 18px;
  line-height: 36px;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 21px;
  }
`;

export const TopRight = styled.div`
  margin-left: 10%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media (max-width: 768px) {
    margin-left: 0px;
  }
`;

export const BulletWrapper = styled.div`
  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

export const Title = styled.ul`
  font-family: ${(props) => props.theme.fonts.Lato};
  color: ${(props) => props.theme.colors.appWhite};
  font-size: 18px;
  line-height: 36px;
  font-weight: 400;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
  }
`;

export const SubTitle = styled.li`
  font-family: ${(props) => props.theme.fonts.Lato};
  list-style: none;
  color: ${(props) => props.theme.colors.appWhite};
  font-size: 16px;
  line-height: 36px;
  font-weight: 400;
  @media (max-width: 768px) {
    margin-top: 32px;
    font-size: 16px;
    line-height: 24px;
  }
`;
export const SubTitleBottom = styled.li`
  font-family: ${(props) => props.theme.fonts.Lato};
  list-style: none;
  color: ${(props) => props.theme.colors.appWhite};
  font-size: 16px;
  line-height: 36px;
  font-weight: 400;

  @media (max-width: 768px) {
    margin-top: 32px;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  /* width: 50%; */
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0 auto;
  }
`;

export const LogoWrapper = styled.div`
  padding: 10px 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0px;
    width: 100%;
  }
`;

export const BottomText = styled.h3`
  font-family: ${(props) => props.theme.fonts.Lato};
  font-size: 16px;
  line-height: 36px;
  color: ${(props) => props.theme.colors.appYellow};
  margin-left: 22px;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 14px;
    line-height: 21px;
  }
`;

export const BottomRight = styled.div``;

export const SocialLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

export const SocialWrapper = styled.li`
  cursor: pointer;
`;

export const StyledNavLink = styled(HashLink)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: rgb(248, 248, 248);
  }
`;
export const StyledNavLinkBottom = styled(HashLink)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration-line: underline;
    color: #bebebe;
  }
`;

export const SocialIcon = styled.img``;

export const SubFooterWrapper = styled.p`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 56px;
  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

export const Copyright = styled.p`
  color: #bebebe;
  font-family: ${(props) => props.theme.fonts.Lato};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.03rem;
  padding-bottom: 22px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
  }
`;
