import styled, { css } from "styled-components";

import whoAreWeLeftBg from "../../assets/LandingSections/whoAreWeLeft.jpg";

export const WhoAreWeContainer = styled.section``;

export const Wrapper = styled.div`
  ${props => props.theme.wrapper};
  max-width: 460px;
  ${props =>
    props.left &&
    css`
      margin-right: 0px;
    `}
  ${props =>
    props.right &&
    css`
      margin-left: 0px;
    `}
    @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;
  }
`;

export const WhoAreWeContent = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const WhoAreWeLeft = styled.div`
  width: 50%;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(${whoAreWeLeftBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center left;
  height: 100vh;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Title = styled.h3`
  text-transform: uppercase;
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  font-family: ${props => props.theme.fonts.Lato};
  color: ${props => props.theme.colors.appWhite};
  .we {
    color: ${props => props.theme.colors.appYellow};
  }
  .doing {
    color: ${props => props.theme.colors.appBlue};
  }
  ${props =>
    props.right &&
    css`
      color: ${props => props.theme.colors.appBlack};
      padding-left: 10%;
    `}
  @media (max-width: 768px) {
    ${props =>
      props.right &&
      css`
        padding-left: 0px;
      `}
  }
`;

export const SubTitle = styled.p`
  color: ${props => props.theme.colors.appWhite};
  font-size: 20px;
  line-height: 36px;
  font-family: ${props => props.theme.fonts.Lato};
  font-weight: 100;
  margin-top: 12px;
  width: 90%;
  ${props =>
    props.right &&
    css`
      color: ${props => props.theme.colors.textLight};
      padding-left: 10%;
    `}
  @media (max-width: 768px) {
    width: 100%;
    padding: 0px 20px;
  }
`;

export const WhoAreWeRight = styled.div`
  width: 50%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
