import React from "react";

//importing components
import AllJobsList from "../../components/AllJobsList/AllJobsList";
//importing styles
import { StyledSection, Wrapper } from "./styles";

const CareersPage = () => {
  return (
    <StyledSection>
      <Wrapper>
        <AllJobsList />
      </Wrapper>
    </StyledSection>
  );
};

export default CareersPage;
