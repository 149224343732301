import styled, {css} from "styled-components";

import InputText from "../../styled-elements/InputText";
import TextArea from "../../styled-elements/TextArea";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 50%;
  @media (max-width: 768px) {
    width: 75%;
  }
  /* align-items: center; */
`;

export const StyledInputText = styled.input`
  ${InputText};
  height: 48px;
  padding: 12px 16px;
  border: 1px solid #6b6b6b;
  border-radius: 8px;
  margin-bottom: 33px;
`;
export const StyledTextArea = styled.textarea`
  ${TextArea};
  resize: none;
  height: 130px;
  margin-bottom: 18px;
  padding: 12px 16px;
  border-radius: 8px;

  /* padding-left: 20px; */
  @media (max-width: 768px) {
    width: 75%;
  }
`;

export const StyledLabel = styled.h3`
  font-family: ${props => props.theme.fonts.Lato};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

export const LabelWrapper = styled.div`
  width: 40%;
  @media (max-width: 768px) {
    width: 75%;
  }
`;

export const ChooseFile = styled.input.attrs({
  type: "file"
})`
  ${InputText};
  height: 51px;
  margin-bottom: 16px;
  padding: 16px 0;
  width: 40%;
  @media (max-width: 768px) {
    width: 75%;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 0 auto;
  margin-top: 28px;
  margin-bottom: 48px;
  width: 200px;
  @media (max-width: 768px) {
    width: 190px;
  }
`;

export const StyledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.appYellow};
  ${props =>
    props.secondary &&
    css`
      background-color: ${props => props.theme.colors.appBlue};
      color: ${props => props.theme.colors.appWhite};
      padding: 4px 8px;
    `};
  ${props =>
    props.whiteButton &&
    css`
      color: ${props => props.theme.colors.appBlack};
      background-color: ${props => props.theme.colors.appWhite};
      padding: 4px 8px;
    `}
`;

export const ButtonText = styled.h3`
  font-weight: 600;
`;

export const ButtonIcon = styled.img`
  margin-top: 1px;
`;
