import styled from "styled-components";

export const Wrapper = styled.div`
  background: linear-gradient(121.89deg, #6e84d5 -15.84%, #374781 108.55%),
    #c4c4c4;
`;

export const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media (max-width: 699px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const LeftWrapper = styled.div`
  margin-top: 188px;
  @media (max-width: 699px) {
    margin-top: 60px;
  }
`;

export const LeftImage = styled.img`
  width: 300px;
  height: auto;
  @media (max-width: 1000px) {
    width: 210px;
  }
`;

export const MiddleWrapper = styled.div`
  margin-top: 55px;
  @media (max-width: 699px) {
    margin-top: 36px;
  }
`;

export const MiddleImage = styled.img`
  width: 300px;
  height: auto;
  @media (max-width: 1000px) {
    width: 204px;
  }
`;

export const RightWrapper = styled.div`
  margin-top: 188px;
  @media (max-width: 699px) {
    margin-top: 36px;
    margin-bottom: 28px;
  }
`;

export const RightImage = styled.img`
  width: 292px;
  height: auto;
  @media (max-width: 1000px) {
    width: 210px;
  }
`;

export const Title = styled.h4`
  font-size: 36px;
  line-height: 60px;
  font-family: ${props => props.theme.fonts.Lato};
  font-weight: 700;
  color: ${props => props.theme.colors.appWhite};
  text-align: center;
  @media (max-width: 699px) {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const ButtonWrapper = styled.div`
  padding-bottom: 50px;
  margin-top: 28px;
  display: flex;
  justify-content: center;
`;

export const ButtonDiv = styled.div`
  background-color: ${props => props.theme.colors.appWhite};
  border-radius: 32px;
  padding: 14px 41px;
  display: inline-block;
`;

export const ButtonText = styled.h5`
  color: rgba(54, 70, 87, 1);
  font-size: 30px;
  line-height: 36px;
  font-weight: 600;
  @media (max-width: 699px) {
    font-size: 20px;
    line-height: 24px;
  }
`;
