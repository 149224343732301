import React, { useRef, useContext, useEffect } from "react";

import axios from "axios";

//importing styled elements

//importing styles
import {
  StyledForm,
  StyledInputText,
  StyledTextArea,
  StyledLabel,
  LabelWrapper,
  ChooseFile,
  ButtonWrapper,
  StyledButton,
  ButtonIcon,
  ButtonText
} from "./styles";

//importing Context
import JobContext from "../../store/JobContext";

// import assets
import sideArrow from "../../assets/images/iconSIdeArrow.svg";

//import components
import PrimaryButton from "../PrimaryButton/PrimaryButton";

const ApplicationForm = () => {
  const jobCtx = useContext(JobContext);

  let fileData;

  useEffect(() => {
    if (jobIdInputRef && !!jobCtx.jobId) {
      //we can check for any --> id||title
      jobIdInputRef.current.value = jobCtx.jobId;
      jobTitleInputRef.current.value = jobCtx.jobTitle;
      // jobIdInputRef.current.focus();
    }
  }, [jobCtx.jobId, jobCtx.jobTitle]);

  const firstNameInputRef = useRef();
  const lastNameInputRef = useRef();
  const emailInputRef = useRef();
  const contactInputRef = useRef();
  const jobIdInputRef = useRef();
  const jobTitleInputRef = useRef();
  const describeInputRef = useRef();
  const fileInputRef = useRef();

  const fileUploadHandler = event => {
    const selectedFile = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(selectedFile);

    reader.onload = event => {
      // console.log(event.target.result);
      fileData = { file: event.target.result };
      // console.log(fileData);
    };
    // console.log(event.target.files[0]);
  };

  // enteredJobId = jobCtx.jobId;

  const submitHandler = async event => {
    event.preventDefault();
    const enteredFirstName = firstNameInputRef.current.value;
    const enteredLastName = lastNameInputRef.current.value;
    const enteredEmail = emailInputRef.current.value;
    const enteredContactNumber = contactInputRef.current.value;
    const enteredJobId = jobIdInputRef.current.value;
    const enteredJobTitle = jobTitleInputRef.current.value;
    const enteredDescription = describeInputRef.current.value;

    firstNameInputRef.current.value = "";
    lastNameInputRef.current.value = "";
    emailInputRef.current.value = "";
    contactInputRef.current.value = "";
    jobIdInputRef.current.value = "";
    jobTitleInputRef.current.value = "";
    describeInputRef.current.value = "";
    fileInputRef.current.value = "";

    var myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append(
      "api-key",
      "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",
    );
    myHeaders.append("content-type", "application/json");
    myHeaders.append(
      "Cookie",
      "__cf_bm=kd1Kk79aITslvmITgLr0MrTlE9yq0_ZtVp9uEzue1fg-1679488998-0-AVkrsaMnZ1Z0sgc//8CbaBrtHs/+bAT9xDkkNj/sLsw8p9u2x853As8gEH0v7nThO78SPdzXjy/7B+at7fdauXo=",
    );

    var raw = JSON.stringify({
      sender: {
        name: "kPaisa",
        email: "kleio.oracle@gmail.com",
      },
      to: [
        {
          email: "careers@kleio.me",
          name: "Careers",
        },
      ],
      subject: `Applied for ${enteredJobId}-${enteredJobTitle} from Kleio`,
      htmlContent: `<html><head></head><body><p>Hello,</p>${enteredDescription}<br/>Name ${enteredFirstName} ${enteredLastName}<br/>contact No ${enteredContactNumber}<br/> email ${enteredEmail}</p></body></html>`,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.sendinblue.com/v3/smtp/email", requestOptions)
      .then((response) => {console.log(response)})
      .then((result) => {})
      .catch((error) => {});
  };

  const styledInputs = [
    {
      label: "First name",
      placeholder: "First Name",
      ref: firstNameInputRef
    },
    {
      label: "Last Name",
      placeholder: "Last Name",
      ref: lastNameInputRef
    },
    {
      label: "EMAIL ID:",
      placeholder: "Email",
      ref: emailInputRef
    },
    {
      label: "Mobile No.",
      placeholder: "Contact Number",
      ref: contactInputRef
    },
    { label: "Job ID", placeholder: "Job ID", ref: jobIdInputRef },
    { label: "Job Title", placeholder: "Job Title", ref: jobTitleInputRef }
  ];

  return (
    <StyledForm>
      {styledInputs.map(eachInput => (
        <>
          <StyledLabel>{eachInput.label}</StyledLabel>
          <StyledInputText
            placeholder={eachInput.placeholder}
            ref={eachInput.ref}
          />
        </>
      ))}
      <StyledLabel>DESCRIBE YOURSELF</StyledLabel>
      <StyledTextArea
        placeholder="Tell ts about yourself/your work"
        ref={describeInputRef}
        rowsValue={8}
      />

      <LabelWrapper>
        <StyledLabel>Upload Resume</StyledLabel>
      </LabelWrapper>

      <ChooseFile
        type="file"
        name="filename"
        id="input"
        class="custom-file-input"
        ref={fileInputRef}
        onChange={e => fileUploadHandler(e)}
      />
      <ButtonWrapper>
      <StyledButton onClick={submitHandler}>
        <ButtonText>Apply Now</ButtonText>
        <ButtonIcon src={sideArrow} />
      </StyledButton>
      </ButtonWrapper>
    </StyledForm>
  );
};

export default ApplicationForm;
