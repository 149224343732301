import styled from "styled-components";

export const StyledSection = styled.section`
  padding: 50px 0;
  background-color: ${props => props.theme.colors.appGrey1};
`;

export const Wrapper = styled.div`
  ${props => props.theme.wrapper};

  .card-img-top {
    background: ${props => props.theme.colors.appGrey2};
  }
  .h6 {
    font-family: ${props => props.theme.fonts.Lato};
  }
`;

export const CardLinkWrapper = styled.a`
  color: ${props => props.theme.colors.appBlack};
`;
