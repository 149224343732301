import styled from "styled-components";
import careersBg from "../../assets/careers/careersBg.jpg";

export const Wrapper = styled.div`
  ${props => props.theme.wrapper};
`;

export const CareerTopSection = styled.section`
  background-image: url(${careersBg});
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 102px;
  padding-bottom: 234px;
  position: relative;
  @media (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: 135px;
  }
`;

export const Title = styled.h2`
  font-family: ${props => props.theme.fonts.Lato};
  color: ${props => props.theme.colors.appWhite};
  font-weight: 700;
  text-align: center;
  font-size: 56px;
  line-height: 84px;
  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 54px;
  }
`;

export const TopImageWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -66px;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const TopImage = styled.img`
  width: 404px;
  height: auto;
  @media (max-width: 768px) {
    width: 70%;
    min-width: 280px;
    height: auto;
  }
`;

export const JobDescriptionWrapper = styled.section`
  margin-top: 104px;
`;

export const SubTitle = styled.h3`
  font-family: ${props => props.theme.fonts.Lato};
  font-size: 40px;
  line-height: 54px;
  font-weight: 700;
  color: ${props => props.theme.colors.appBlack};
  text-align: center;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 30px;
    line-height: 45px;
  }
`;
export const JobTitle = styled.h3`
  font-family: ${props => props.theme.fonts.Lato};
  font-size: 20px;
  line-height: 36px;
  font-weight: 700;
  color: ${props => props.theme.colors.textLight};
  text-align: center;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const JobDetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    margin-top: 30px;
  }
`;

export const JobContentWrapper = styled.div``;

export const JobDetailTitle = styled.h4`
  font-family: ${props => props.theme.fonts.Lato};
  font-size: 20px;
  line-height: 36px;
  font-weight: 700;
  color: ${props => props.theme.colors.appBlack};
  text-align: center;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const JobDetailSubTitle = styled.h5`
  font-family: ${props => props.theme.fonts.Lato};
  font-size: 20px;
  line-height: 36px;
  font-weight: 400;
  color: ${props => props.theme.colors.appBlack};
  text-align: center;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const DescriptionWrapper = styled.div`
  margin-top: 40px;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;
export const Description = styled.h5`
  font-family: ${props => props.theme.fonts.Lato};
  font-size: 20px;
  line-height: 36px;
  font-weight: 400;
  color: ${props => props.theme.colors.textLight};
  margin-top: 20px;
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    font-size: 14px;
    line-height: 21px;
    margin-top: 20px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 250px;
  margin: 0 auto;
  margin-top: 48px;
  margin-bottom: 48px;
  @media (max-width: 768px) {
    width: 190px;
  }
`;
