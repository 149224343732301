import React from "react";

//importing components

//importing styles
import {
  Wrapper,
  ProductContainer,
  Title,
  TopImageWrapper,
  TopImage,
  SubTitle,
  RowWrapper,
  //ProductList
  ProductDescription,
  ProductLogo,
  ProductLogoWrapper,
  ProductRightSide,
  ProductRightTop,
  ProductTitle,
  ProductWrapper,
  ProductsList,
  ProductContent,
  // Downloadables
  DownloadWrapper,
  AppleWrapper,
  AndroidWrapper,
  DownloadImg,
  LinkWrapper,
  ComingSoonWrapper,
  ComingSoonText
} from "./styles";

// import assets
import productTop from "../../assets/Products/productsTop.png";
import fitlogLogo from "../../assets/Products/fitlogApp.jpg";
import Android from "../../assets/Products/androidBlack.svg";
import Apple from "../../assets/Products/appleBlack.svg";

const products = [
  {
    title: "Payments App",
    logo: fitlogLogo,
    launched: false,
    description:
      "Payment app meant to simplify money transfers and bill payments for people. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus arcu nulla sed ullamcorper sed. Nulla cras et lectus et. Pellentesque id sit aliquet adipiscing convallis. Maecenas non, fermentum, sed rhoncus risus fermentum et odio porttitor."
  },
  {
    title: "Inbima",
    logo: fitlogLogo,
    launched: false,
    description:
      "Payment app meant to simplify money transfers and bill payments for people. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus arcu nulla sed ullamcorper sed. Nulla cras et lectus et. Pellentesque id sit aliquet adipiscing convallis. Maecenas non, fermentum, sed rhoncus risus fermentum et odio porttitor."
  },
  {
    title: "Fitlog",
    logo: fitlogLogo,
    launched: true,
    description:
      "Payment app meant to simplify money transfers and bill payments for people. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus arcu nulla sed ullamcorper sed. Nulla cras et lectus et. Pellentesque id sit aliquet adipiscing convallis. Maecenas non, fermentum, sed rhoncus risus fermentum et odio porttitor."
  }
];

const DownloadableLinks = () => {
  return (
    <DownloadWrapper>
      <AndroidWrapper>
        <LinkWrapper
          href="https://play.google.com/store/apps/details?id=com.kleio.fitlog"
          target="_blank"
          rel="noreferrer"
        >
          <DownloadImg src={Android} />
        </LinkWrapper>
      </AndroidWrapper>
      <ProductTitle> | </ProductTitle>
      <AppleWrapper>
        <DownloadImg src={Apple} />
      </AppleWrapper>
    </DownloadWrapper>
  );
};

const ComingSoon = () => {
  return (
    <ComingSoonWrapper>
      <ComingSoonText>Coming Soon</ComingSoonText>
    </ComingSoonWrapper>
  );
};

const ProductsNewPage = () => {
  return (
    <>
      {/* Banner starts */}
      <ProductContainer>
        <Wrapper>
          <Title>PRODUCTS</Title>
          <TopImageWrapper>
            <TopImage src={productTop} />
          </TopImageWrapper>
        </Wrapper>
      </ProductContainer>
      {/* banner ends */}
      {/* Row 1 starts */}
      <RowWrapper>
        <Wrapper>
          <SubTitle>ALL PRODUCTS</SubTitle>
          <ProductsList>
            {products.map((product, index) => {
              return (
                <ProductWrapper>
                  <ProductContent>
                    <ProductLogoWrapper>
                      <ProductLogo src={product.logo} />
                    </ProductLogoWrapper>
                    <ProductRightSide>
                      <ProductRightTop>
                        <ProductTitle>{product.title}</ProductTitle>
                        {product.launched ? (
                          <DownloadableLinks />
                        ) : (
                          <ComingSoon />
                        )}
                      </ProductRightTop>
                      <ProductDescription>
                        {product.description}
                      </ProductDescription>
                    </ProductRightSide>
                  </ProductContent>
                </ProductWrapper>
              );
            })}
          </ProductsList>
        </Wrapper>
      </RowWrapper>
      {/* Row 1 ends */}
    </>
  );
};

export default ProductsNewPage;
