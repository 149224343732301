import styled from "styled-components";

export const StyledTableWrapper = styled.div`
  font-family: ${props => props.theme.fonts.Lato};
  width: 90%;
  margin: 50px auto;
`;

export const Styledtr = styled.tr`
  cursor: pointer;
`;

export const Wrapper = styled.div`
  ${props => props.theme.wrapper};

  .table-dark {
    background-color: ${props => props.theme.colors.appBlueInner};
  }
  .th {
    border-color: ${props => props.theme.colors.appWhite};
  }
`;

export const StyledSection = styled.section`
  background-color: ${props => props.theme.colors.appGrey1};
  padding: 50px 0;
`;

// export const StyledLink = styled(Link)`
//   text-decoration: none;

//   &:focus,
//   &:hover,
//   &:visited,
//   &:link,
//   &:active {
//     text-decoration: none;
//   }
// `;
