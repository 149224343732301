import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { debounce } from "../../utils/helpers";

// styles
import {
  StyledUL,
  StyledHashLink,
  LogoStyledLink,
  StyledHeader,
  Styledh1,
  StyledNav,
  LogoWrapper,
  RightLinks,
  // HiringWrapper,
  // HiringTriangle,
  // HiringRectangle,
  // HiringTag,
  // HiringText,
  StyledItem,
  Hamburger,
  Wrapper,
  StyledOutLink,
  StyledLink,
  AboveWrapper,
  HeaderRight,
  ContactUsWrapper,
} from "./styles";

// assets
import kleioWhite from "../../assets/images/kleioWhite.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { CgClose } from "react-icons/cg";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;
    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 70) ||
        currentScrollPos < 10
    );
    setPrevScrollPos(currentScrollPos);
  }, 100);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  const location = useLocation();
  useEffect(() => {
    // console.log(location.pathname);
  }, [location]);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <AboveWrapper visible={visible}>
      <Wrapper>
        <StyledHeader>
          <Styledh1>
            <LogoStyledLink
              to="/"
              title="Kleio"
              onClick={() => scroll.scrollToTop()}
            >
              <LogoWrapper>
                <img src={kleioWhite} width={80} height={"auto"} alt="Kleio" />
              </LogoWrapper>
            </LogoStyledLink>
          </Styledh1>

          <Hamburger onClick={handleClick}>
            {!isOpen && <GiHamburgerMenu size={35} color="white" />}
            {isOpen && <CgClose size={35} color="white" />}
          </Hamburger>

          <HeaderRight isOpen={isOpen}>
            <StyledNav isHome={location.pathname === "/"} isOpen={isOpen}>
              <StyledUL isOpen={isOpen}>
                <RightLinks>
                  {/* About  */}
                  <StyledItem>
                    <StyledLink
                      to="/#ourstory"
                      isOpen={isOpen}
                      smooth={true}
                      onClick={handleClick}
                      // activeClass="active"
                      // to="whoarewe"
                      // spy={true}
                      // smooth={true}
                      // duration={1000}
                    >
                      About
                    </StyledLink>
                  </StyledItem>

                  {/* Products */}
                  <StyledItem>
                    <StyledLink
                      to="/#products"
                      isOpen={isOpen}
                      smooth={true}
                      onClick={handleClick}
                    >
                      Products
                    </StyledLink>
                  </StyledItem>
                  <StyledItem>
                    <StyledLink
                      to="/#careers"
                      isOpen={isOpen}
                      smooth={true}
                      onClick={handleClick}
                      activeClass="active"
                    >
                      Careers
                    </StyledLink>
                  </StyledItem>

                  <StyledItem>
                    <StyledOutLink
                      isOpen={isOpen}
                      to="/blogs"
                      onClick={handleClick}
                    >
                      Blog
                    </StyledOutLink>
                  </StyledItem>
                </RightLinks>
              </StyledUL>
            </StyledNav>

            <ContactUsWrapper>
              <StyledItem>
                <StyledHashLink
                  isOpen={isOpen}
                  smooth={true}
                  to="/#contact"
                  onClick={handleClick}
                >
                  Get in touch
                </StyledHashLink>
              </StyledItem>
            </ContactUsWrapper>
          </HeaderRight>
        </StyledHeader>
      </Wrapper>
    </AboveWrapper>
  );
};

export default Header;
