import React from "react";
import { StyledNavlink, StyledButton, ButtonText, ButtonIcon } from "./styles";

// Import assets
import sideArrow from "../../assets/images/iconSIdeArrow.svg";
import whiteArrow from "../../assets/images/iconWhiteArrow.svg";

const PrimaryButton = ({
  text,
  to = "https://www.kpaisa.com",
  sideIcon = true,
  handleClick = ()=>{}
}) => {

  return (
    <StyledNavlink to={to}>
      <StyledButton onClick={handleClick}>
        <ButtonText>{text}</ButtonText>
        {sideIcon && <ButtonIcon src={sideArrow} />}
      </StyledButton>
    </StyledNavlink>
  );
};

export default PrimaryButton;
