import React, { useRef } from "react";
import axios from "axios";

//importing components
import ContactForm from "../../components/ContactForm/ContactForm";

//importing styles
import {
  ContentWrapper,
  Title,
  SubTitle,
  Wrapper,
  StyledSection,
  ImageSide,
  ImageStory,
  FormSide,
  MailText,
  LeftSide,
  RightSide,
  TopTitle,
  TopSubTitle,
  SocialLinksWrapper,
  SocialIcon,
  SubFooterWrapper,
  SocialWrapper,
  StyledNavLink,
  FormWrapper,
  FormRowWrapper,
  SmallField,
  FormLabel,
  FormInput,
  StyledTextArea,
  StyledButton,
  StyledButtonWrapper,
} from "./styles";

//import assets
import contactUs from "../../assets/contact/contactUsBg.png";

import linkedin from "../../assets/contact/linkedin.svg";
import twitter from "../../assets/contact/twitter.svg";
import instagram from "../../assets/contact/instagram.svg";
import facebook from "../../assets/contact/facebook.svg";

const ContactUsPage = () => {
  const nameInputRef = useRef();
  const emailInputRef = useRef();
  const messageRef = useRef();

  const submitHandler = event => {
    event.preventDefault();
    const enteredName = nameInputRef.current.value;
    const enteredEmail = emailInputRef.current.value;
    const enteredMessage = messageRef.current.value;

    nameInputRef.current.value = "";  
    emailInputRef.current.value = "";
    messageRef.current.value = "";

    var myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append(
      "api-key",
      "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",
    );
    myHeaders.append("content-type", "application/json");
    myHeaders.append(
      "Cookie",
      "__cf_bm=kd1Kk79aITslvmITgLr0MrTlE9yq0_ZtVp9uEzue1fg-1679488998-0-AVkrsaMnZ1Z0sgc//8CbaBrtHs/+bAT9xDkkNj/sLsw8p9u2x853As8gEH0v7nThO78SPdzXjy/7B+at7fdauXo=",
    );

    var raw = JSON.stringify({
      sender: {
        name: "kPaisa",
        email: "kleio.oracle@gmail.com",
      },
      to: [
        {
          email: "contact@kleio.me",
          name: "Contact",
        },
      ],
      subject: "Mail from Website contact from Kleio",
      htmlContent: `<html><head></head><body><p>Hello,</p>${enteredMessage}<br/>name ${enteredName}<br/>email ${enteredEmail}</p></body></html>`,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.sendinblue.com/v3/smtp/email", requestOptions)
    .then((response) => {console.log(response)})
    .then((result) => {})
    .catch((error) => {});
  };

  return (
    <StyledSection id="contact">
      <Wrapper>
        <ContentWrapper>
          <LeftSide>
            <TopTitle>Let's talk</TopTitle>
            <TopSubTitle>
              We'd love to learn more about you and what we can be together.
            </TopSubTitle>
            <TopSubTitle>
              You can use the form alongside to reach out to us or email us on
              contact@kleio.com
            </TopSubTitle>

            <SocialLinksWrapper>
              <SocialWrapper>
                <StyledNavLink
                  to={{
                    pathname:
                      "https://www.linkedin.com/company/kleio-innovation-labs-pvt-ltd/",
                  }}
                  target="_blank"
                >
                  <SocialIcon src={linkedin} />
                </StyledNavLink>
              </SocialWrapper>

              <SocialWrapper>
                <StyledNavLink
                  to={{
                    pathname: "https://twitter.com/officialkpaisa",
                  }}
                  target="_blank"
                >
                  <SocialIcon src={twitter} />
                </StyledNavLink>
              </SocialWrapper>

              <SocialWrapper>
                <StyledNavLink
                  to={{
                    pathname: "https://www.instagram.com/officialkpaisa/",
                  }}
                  target="_blank"
                >
                  <SocialIcon src={instagram} />
                </StyledNavLink>
              </SocialWrapper>

              <SocialWrapper>
                <StyledNavLink
                  to={{
                    pathname: "https://www.facebook.com/kleio.me",
                  }}
                  target="_blank"
                >
                  <SocialIcon src={facebook} />
                </StyledNavLink>
              </SocialWrapper>
            </SocialLinksWrapper>
          </LeftSide>
          <RightSide>
            <FormWrapper>
              <FormRowWrapper>
                <SmallField>
                  <FormLabel>Name</FormLabel>
                  <FormInput placeholder="Name"  ref={nameInputRef}/>
                </SmallField>
                <SmallField>
                  <FormLabel>Email</FormLabel>
                  <FormInput placeholder="Email" ref={emailInputRef} />
                </SmallField>
              </FormRowWrapper>
              <FormLabel>Message</FormLabel>
              <StyledTextArea placeholder="Message" ref={messageRef}/>
              <StyledButtonWrapper>
                <StyledButton onClick={submitHandler}>Submit</StyledButton>
              </StyledButtonWrapper>
            </FormWrapper>
          </RightSide>
        </ContentWrapper>
      </Wrapper>
    </StyledSection>
  );
};

export default ContactUsPage;
