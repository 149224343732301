import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Number = styled.h2`
  font-family: Roboto;
  font-size: 120px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  opacity: 0.95;
  color: ${(props) => props.theme.colors.appLightDark50};
  ${(props) =>
    props.darkBg &&
    css`
      color: #3f3f3f;
    `}
`;

export const Line = styled.div`
  opacity: 0.95;
  background-color: ${(props) => props.theme.colors.appLightDark50};
  height: 8px;
  border-radius: 8px;
  width: 100%;
  ${(props) =>
    props.darkBg &&
    css`
      background-color: #3f3f3f;
    `}
`;

export const SectionName = styled.h2`
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.3;
  color: ${(props) => props.theme.colors.appDark400};
  margin-top: -3rem;
  z-index: 10;
  position: relative;
  margin-left: 1rem;
  ${(props) =>
    props.darkBg &&
    css`
      color: #eee;
    `}
`;
