import styled, { css } from "styled-components";

import careersBg from "../../assets/careers/careersBg.jpg";
import { NavLink } from "react-router-dom";

export const Wrapper = styled.div`
  ${(props) => props.theme.wrapper};
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const AboutContainer = styled.section`
  background-color: ${(props) => props.theme.colors.appDark900};
`;

export const TitleTop = styled.h3`
  text-align: center;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  color: #bebebe;
  padding-top: 5%;
  @media (max-width: 810px) {
    padding-top: 15%;
  }
  @media (max-width: 500px) {
    padding-top: 20%;
  }
`;

export const Caption = styled.h2`
  font-family: Roboto;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  text-align: center;
  margin-top: 15px;
  color: ${(props) => props.theme.colors.appWhite};
  width: 40%;
  align-self: center;
  padding-bottom: 20%;

  @media (max-width: 810px) {
    font-size: 32px;
    width: 70%;
    padding-bottom: 30%;
  }
  @media (max-width: 500px) {
    font-size: 27px;
    width: 90%;
    padding-bottom: 50%;
  }
`;

export const WhiteTopSection = styled.div`
  padding: 52px;
  position: relative;
  z-index: 1;
  top: -7rem;
  // bottom: 20px;
  background-color: ${(props) => props.theme.colors.appWhite};
  border-radius: 50px;
`;

export const OurStoryContainer = styled.div``;

export const Text = styled.h2`
  font-family: Roboto;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  color: #171717;
  text-align: center;
  margin-bottom: 24px;
`;

export const Description = styled.p`
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #686868;
  text-align: center;

  @media (max-width: 810px) {
    margin-top: 24px;
  }
`;

export const JobWrapper = styled.div`
  width: 100%;
  margin-top: -5rem;
  margin-bottom: -5rem;
  padding: 4% 8%;
  padding-bottom: 200px;
`;

export const JobDivider = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.colors.appLightDark100};
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
`;

export const TopText = styled.h3`
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  color: ${(props) => props.theme.colors.appLightDark75};
  margin-top: 120px;
  margin-bottom: 24px;
`;

export const TopRowLeftText = styled.h3`
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  color: #3f3f3f;
  @media (max-width: 810px) {
    font-size: 24px;
  }
  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

export const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width: 92%;
  margin-bottom: 32px;
`;

export const BottomRow = styled.div`
  display: flex;
`;

export const ItemImage = styled.img`
  margin-right: 8px;

  @media (max-width: 500px) {
    margin-right: 4px;
  }
`;

export const KnowMoreWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const KnowMoreIcon = styled.img``;

export const BottomItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
  @media (max-width: 500px) {
    margin-right: 12px;
  }
`;

export const BottomItemText = styled.h3`
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #686868;
  @media (max-width: 810px) {
    font-size: 16px;
  }
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

export const TopRowRightText = styled.h3`
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #353535;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const CareerTopSection = styled.section`
  background-image: url(${careersBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  padding-top: 102px;
  padding-bottom: 234px;
  position: relative;
  @media (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: 135px;
  }
`;

export const Title = styled.h2`
  font-family: ${(props) => props.theme.fonts.Lato};
  color: ${(props) => props.theme.colors.appWhite};
  font-weight: 700;
  text-align: center;
  font-size: 56px;
  line-height: 84px;
  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 54px;
  }
`;

export const TopImageWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -66px;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const TopImage = styled.img`
  width: 404px;
  height: auto;
  @media (max-width: 768px) {
    width: 70%;
    min-width: 280px;
    height: auto;
  }
`;

export const RowWrapper = styled.section``;

export const SubTitle = styled.h3`
  font-family: ${(props) => props.theme.fonts.Lato};
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.appBlack};
  text-align: center;
  margin-top: 115px;
  ${(props) =>
    props.second &&
    css`
      margin-top: 80px;
    `}
  @media (max-width: 768px) {
    text-align: center;
    font-size: 30px;
    line-height: 45px;
    ${(props) =>
      props.second &&
      css`
        margin-top: 40px;
      `}
  }
`;

//Jobs
export const JobsWrapper = styled.div`
  margin-top: 46px;
`;

export const JobTitle = styled.h3`
  font-family: ${(props) => props.theme.fonts.Lato};
  font-size: 20px;
  line-height: 36px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.appBlack};
  @media (max-width: 768px) {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
  }
`;

export const JobDetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -150px;
  width: 140px;
  justify-content: space-between;
  @media (max-width: 768px) {
    margin-left: 0px;
    width: 100%;
    max-width: 120px;
    margin: 4px 0;
  }
`;

export const JobType = styled.h4`
  font-family: ${(props) => props.theme.fonts.Lato};
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.textLight};
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const JobExp = styled.h4`
  font-family: ${(props) => props.theme.fonts.Lato};
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.textLight};
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const JobApplyText = styled.h4`
  font-family: ${(props) => props.theme.fonts.Lato};
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.appBlue};
  text-decoration: underline;
  text-underline-position: under;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const JobApplyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }
`;

export const JobApplyArrowWrapper = styled.div`
  margin-left: 8px;
  @media (max-width: 768px) {
    margin-left: 4px;
  }
`;

export const ApplyArrow = styled.img`
  @media (max-width: 768px) {
    width: 80%;
    height: auto;
    margin-top: 5px;
  }
`;

export const StyledNavlink = styled(NavLink)`
  /* color: white; */
  border: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;
