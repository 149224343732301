import React, { useState, useEffect } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css";
import { Link, useHistory } from "react-router-dom";
import { Slide } from "react-awesome-reveal";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {
  Wrapper,
  StyledSection,
  StoryContent,
  StoryLeft,
  Title,
  SubTitle,
  StoryRight,
  ReadLink,
  TitleRight,
  JourneyWrapper,
  TimelineTitle,
  TimelineContent,
  AboutTitle,
  MainTitle,
  Description,
  LearnMoreWrapper,
  LearnMoreText,
  TileTitle,
  TileImage,
  TileSubTitle,
  TileWrapper,
  TileSection,
  SectionNumberWrapper,
} from "./styles";

// import assets
import idea from "../../assets/about/idea.svg";
import ideaColor from "../../assets/about/ideaColor.svg";
import empowerment from "../../assets/about/empowerment.svg";
import empowermentColor from "../../assets/about/empowermentColor.svg";
import excellence from "../../assets/about/excellence.svg";
import excellenceColor from "../../assets/about/excellenceColor.svg";
import collaboration from "../../assets/about/collaboration.svg";
import collaborationColor from "../../assets/about/collaborationColor.svg";

import SectionNumber from "../../components/SectionNumber/SectionNumber";

const tileData = [
  {
    title: "Innovation",
    tileSrc: idea,
    tileSrcActive: ideaColor,
    tileSubTitle: "Reimagining possibilites",
    active: true,
  },
  {
    title: "Empowerment",
    tileSrc: empowerment,
    tileSrcActive: empowermentColor,
    tileSubTitle: "Enabling potential",
    active: false,
  },
  {
    title: "Collaboration",
    tileSrc: collaboration,
    tileSrcActive: collaborationColor,
    tileSubTitle: "Striving together",
    active: false,
  },
  {
    title: "Excellence",
    tileSrc: excellence,
    tileSrcActive: excellenceColor,
    tileSubTitle: "Shaping Future",
    active: false,
  },
];

const AboutUsPage = () => {
  const history = useHistory();

  const [tileDataState, setTileDataState] = useState(tileData);
  const [active, setActive] = useState(0);

  useEffect(() => {
    //This is how you use setInterval to change the active index every 5 seconds
    setInterval(() => {
      const tempState = [...tileDataState];

      setActive((previous) => {
        let currIndex = (previous + 1) % tempState.length;

        tempState.forEach((item, index) => {
          if (index === currIndex) {
            item.active = true;
          } else {
            item.active = false;
          }
        });

        setTileDataState(tempState);
        return currIndex;
      });
    }, 2600); //Change every 1 seconds
  }, []);

  const Tile = ({ title, tileSrc, tileSrcActive, tileSubTitle, active }) => {
    return (
      <TileWrapper active={active}>
        <TileTitle active={active}>{title}</TileTitle>
        {active ? (
          <TileImage src={tileSrcActive} />
        ) : (
          <TileImage src={tileSrc} />
        )}
        <TileSubTitle active={active}>{tileSubTitle}</TileSubTitle>
      </TileWrapper>
    );
  };

  return (
    <StyledSection id="ourstory">
      <Wrapper>
        <ScrollAnimation animateIn="animate__zoomIn" animatePreScroll={false}>
          <SectionNumberWrapper>
            <SectionNumber number="01" text="About Us" />
          </SectionNumberWrapper>
          <StoryContent>
            <StoryLeft>
              <MainTitle>Our Story & Mission</MainTitle>
              <Description>
                At Kleio, our mission is to redefine the financial landscape
                through innovation, technology, and a shared commitment to
                excellence. As a Startup-India recognized organization, we're
                driven by the belief that finance should be a catalyst for
                growth, not a barrier. With Kleio, finance becomes a tool for
                empowerment, innovation, and a brighter future. Join us on this
                journey as we reshape the world of finance, one breakthrough at
                a time.
              </Description>
              <LearnMoreWrapper
                onClick={() => {
                  history.push("/about");
                  console.log("Hello");
                }}
              >
                <LearnMoreText>Learn More</LearnMoreText>
              </LearnMoreWrapper>
            </StoryLeft>
            <StoryRight>
              <TileSection>
                {tileDataState.map((item, index) => {
                  return (
                    <Tile
                      title={item.title}
                      tileSrc={item.tileSrc}
                      tileSrcActive={item.tileSrcActive}
                      tileSubTitle={item.tileSubTitle}
                      active={item.active}
                    />
                  );
                })}
              </TileSection>
            </StoryRight>
          </StoryContent>
        </ScrollAnimation>
      </Wrapper>
    </StyledSection>
  );
};

export default AboutUsPage;
