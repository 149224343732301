import styled, { css, keyframes } from "styled-components";
import { HashLink } from "react-router-hash-link";

export const Wrapper = styled.div`
  ${(props) => props.theme.wrapper};
`;

export const ProductWrapper = styled.div`
  position: relative;
`;

export const InvisibleBlock = styled.div`
  height: 50;
  width: 50;
  background-color: "transparent";
  margin-top: 0;
  position: absolute;
  z-index: 2;
  top: 98%;
`;

export const StyledSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 112px 0px;
`;

export const ContentWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.appDark900};
  border-radius: 50px;
  padding: 52px 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 810px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
  @media (max-width: 500px) {
    justify-content: flex-start;
    padding: 26px 32px;
  }
`;

export const RightContentTest = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  overflow: hidden;

  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-top: 48px;
  }
`;

export const MarqueeDown = styled.div`
  height: 40rem;

  animation: marquee 12s linear infinite alternate;

  @keyframes marquee {
    from {
      ${(props) =>
        props.direction === "right" &&
        css`
          transform: translateX(-92em);
        `}
      ${(props) =>
        props.direction === "down" &&
        css`
          transform: translateY(-100%);
        `}
    }
    to {
      ${(props) =>
        props.direction === "right" &&
        css`
          transform: translateX(0em);
        `}
      ${(props) =>
        props.direction === "down" &&
        css`
          transform: translateY(0%);
        `}
    }
  }

  @media (max-width: 1024px) {
    height: 20rem;
  }
  @media (max-width: 480px) {
    height: 25rem;
  }
  @media (max-width: 420px) {
    height: 30rem;
  }
`;

export const MarqueeUp = styled.div`
  height: 40rem;

  animation: marquee 12s linear infinite alternate-reverse;

  @keyframes marquee {
    from {
      ${(props) =>
        props.direction === "left" &&
        css`
          transform: translateX(-92em);
        `}
      ${(props) =>
        props.direction === "up" &&
        css`
          transform: translateY(0%);
        `}
    }
    to {
      ${(props) =>
        props.direction === "left" &&
        css`
          transform: translateX(0em);
        `}
      ${(props) =>
        props.direction === "up" &&
        css`
          transform: translateY(-100%);
        `}
    }
  }

  @media (max-width: 1024px) {
    height: 20rem;
  }
`;

export const RightContentWrapper = styled.div`
  margin: 75px 0px;
  @media (max-width: 1024px) {
    margin: 0px;
  }
`;
export const RightContentRightWrapper = styled.div`
  margin: 75px 0px;
  @media (max-width: 1024px) {
    margin: 0px;
  }
`;

export const LeftContent = styled.div`
  width: 45%;

  @media (max-width: 1100px) {
    width: 100%;
  }
  @media (max-width: 810px) {
    width: 100%;
  }
`;

export const RightContent = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  @media (max-width: 1100px) {
    width: 100%;
  }
  @media (max-width: 810px) {
    width: 100%;
    margin-top: 20px;
  }
  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

export const LeftContentTest = styled.div`
  width: 43%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const ProductsTitle = styled.div`
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  color: ${(props) => props.theme.colors.appLightDark75};
  margin-top: 54.5px;
  @media (max-width: 810px) {
    display: none;
  }
`;

export const RightInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MainTitle = styled.div`
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  color: ${(props) => props.theme.colors.appWhite};
  @media (max-width: 1024px) {
    font-size: 36px;
  }
  @media (max-width: 850px) {
    font-size: 32px;
    text-align: center;
  }
  @media (max-width: 500px) {
    font-size: 28px;
    text-align: left;
    width: 100%;
  }
`;

export const TopRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 52px;
  @media (max-width: 810px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 500px) {
    justify-content: flex-start;
  }
`;

export const TopRowSideContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 810px) {
    width: 75%;
    justify-content: center;
    margin: 24px 0px;
  }
  @media (max-width: 500px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const TopRowSideContentSingle = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 3em;
  @media (max-width: 810px) {
    width: 75%;
    justify-content: center;
    margin: 24px 0px;
  }
  @media (max-width: 500px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const RowItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const RowText = styled.h4`
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  width: 80%;
  margin-top: 7px;

  @media (max-width: 1024px) {
    width: 90%;
  }
  @media (max-width: 810px) {
    width: 90%;
  }
  @media (max-width: 500px) {
    width: 90%;
  }
`;

export const RowImage = styled.img``;

export const ProductDesc = styled.p`
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${(props) => props.theme.colors.appLightDark100};
  margin: 24px 0px;

  @media (max-width: 810px) {
    text-align: center;
  }
  @media (max-width: 500px) {
    text-align: left;
  }
`;

export const LearnMoreButton = styled.button`
  background-color: ${(props) => props.theme.colors.appWhite};
  border-radius: 100px;
  padding: 16px 24px;

  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${(props) => props.theme.colors.appDark900};
  text-align: center;
  cursor: pointer;
  border: none;
`;

export const LearnMoreButtonWrapper = styled.div`
  @media (max-width: 810px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 500px) {
    justify-content: flex-start;
  }
`;

export const LearnMoreText = styled.h3`
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${(props) => props.theme.colors.appDark900};
`;

export const MainImage = styled.img`
  height: 580px;
  width: auto;
  object-fit: contain;
  position: relative;
  @media (max-width: 1024px) {
    height: 450px;
  }
  @media (max-width: 900px) {
    height: 420px;
  }
  @media (max-width: 810px) {
    height: 480px;
  }
  @media (max-width: 500px) {
    height: 360px;
  }
`;

export const SubImage = styled.img`
  height: 240px;
  width: auto;
  object-fit: contain;
  position: absolute;
  bottom: 20px;
  margin-left: 100px;
  // ${(props) => props?.fitlog} ? margin-left : 120px : margin-left : 120px;
`;

export const TestimonialsWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.appWhite};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 112px;
  overflow: hidden;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  /* height: 100vh; */
`;

export const TestimonialTitle = styled.h3`
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  color: ${(props) => props.theme.colors.appDark400};
  margin-top: 120px;
  margin-bottom: 24px;
`;

export const TestimonialBigTitle = styled.h2`
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-top: 24px;
  color: ${(props) => props.theme.colors.appDark900};
`;

export const TestimonialSubTitle = styled.h3`
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${(props) => props.theme.colors.appDark600};
  margin-top: 24px;
`;

export const Title = styled.h2`
  text-align: center;
  font-family: ${(props) => props.theme.fonts.Poppins};
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.appBlack};
  .products {
    color: ${(props) => props.theme.colors.appBlue};
  }
  @media (max-width: 810px) {
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    margin-top: 60px;
  }
`;

export const SubTitle = styled.p`
  font-family: ${(props) => props.theme.fonts.Poppins};
  font-size: 20px;
  line-height: 36px;
  font-weight: 400;
  text-align: center;
  color: ${(props) => props.theme.colors.appLightGrey};
  margin-top: 12px;
  @media (max-width: 810px) {
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    margin-top: 12px;
    width: 100%;
    padding: 0px 20px;
    margin: 0 auto;
    min-width: 320px;
  }
`;

export const ReviewsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    flex-direction: row;
    width: 150%;
  }
  @media (max-width: 800px) {
    width: 200%;
  }
  @media (max-width: 480px) {
    width: 200%;
  }
`;

export const ReviewCard = styled.div`
  padding: 24px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.appLightDark50};
  margin-bottom: 16px;
  width: 300px;

  @media (max-width: 1024px) {
    margin-right: 20px;
    min-width: 13em;
  }
`;

export const ReviewTopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
`;

export const ReviewName = styled.h3`
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: ${(props) => props.theme.colors.appDark900};
`;

export const ReviewImg = styled.img`
  width: 1.2rem;
  margin-right: 0.5rem;
`;

export const ReviewText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${(props) => props.theme.colors.appDark600};
  margin: 16px 0px;
  white-space: wrap;
`;

export const ReviewDate = styled.h3`
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${(props) => props.theme.colors.appDark500};
`;

const fade_in_show = keyframes`
 0% {
          opacity: 0;
          transform: translate(25%,25%)
     }

     100% {
          opacity: 1;
          transform: translate(0%,0%);
     }
`;
const slide_up = keyframes`
 0% {
          opacity: 0;
          transform: translateY(100%)
     }

     100% {
          opacity: 1;
          transform: translateY(0%)
     }
`;

export const ProductCard = styled.div`
  background: linear-gradient(
      156.67deg,
      rgba(252, 135, 71, 0.4) 0%,
      rgba(252, 163, 79, 0.4) 43.67%,
      rgba(252, 220, 96, 0.4) 99.11%
    ),
    #ffffff;
  width: 320px;
  position: relative;
  overflow: hidden;
  margin: 4px;
  cursor: pointer;
  :hover > div {
    :nth-child(2) {
      display: block;
      animation: ${slide_up} 1s;
    }
    :nth-child(1) {
      animation: ${fade_in_show} 1s;
      transform: translate(0%, 0%);
      /* transition: linear; */
    }
  }
  @media (max-width: 810px) {
    margin: 16px 0px;
  }
`;

export const ProductImageWrapper = styled.div`
  justify-content: center;
  display: flex;
  padding-bottom: 60px;
  transform: translate(30%, 40%);
`;

export const ProductImage = styled.img`
  width: 220px;
  height: auto;
`;

export const ProductDescription = styled.div`
  background: linear-gradient(
    176.86deg,
    #000000 -28.07%,
    rgba(0, 0, 0, 0) 220.85%
  );
  display: none;
  padding: 24px 0px;
  position: absolute;
  bottom: 0px;
  width: 100%;
`;

export const ProductTitle = styled.h4`
  color: ${(props) => props.theme.colors.appWhite};
  font-family: ${(props) => props.theme.fonts.Poppins};
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 18px;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  width: 200px;
  margin: 0 auto;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 66px;
  justify-content: center;
  @media (max-width: 810px) {
    flex-direction: column;
    margin-top: 20 0px;
    align-items: center;
  }
`;

export const StyledNavLink = styled(HashLink)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: rgb(248, 248, 248);
  }
`;
