import React from "react";

//importing styles
import {
  StyledSection,
  Wrapper,
  ErrorImage,
  ImageWrapper,
  ErrorText,
  HeaderText,
  ParaText,
  BulletText,
  LastText,
  MailText,
} from "./styles";

import errorImage from "../../assets/images/imageError.png";

const ErrorPage = () => {
  return (
    <StyledSection>
      <Wrapper>
        <ErrorText>Terms and Condition</ErrorText>
        <HeaderText>General Provisions </HeaderText>
        <ParaText>
          The Company may add to, modify or remove any part of these Terms and
          Conditions of Use at any time, without notice. Any changes to these
          Terms and Conditions of Use or any terms posted on this Site apply as
          soon as they are posted. By continuing to use this Site after any
          changes are posted, you are indicating your acceptance of those
          changes. The Company may add, change, discontinue, remove or suspend
          any other Content posted on this Site, including features and
          specifications of products described or depicted on the Site,
          temporarily or permanently, at any time, without notice and without
          liability. The Company reserves the right to undertake all necessary
          steps to ensure that the security, safety and integrity of the
          Company's systems as well as its clients and users interests are and
          remain, well-protected.The Company reserves its exclusive right in its
          sole discretion to alter, limit or discontinue the Site or any
          material posted herein, in any respect. The Company shall have no
          obligation to take the needs of any User into consideration in
          connection therewith. The Company reserves its right to deny in its
          sole discretion any user access to this Site or any portion hereof
          without notice. No waiver by the Company of any provision of these
          Terms and Conditions shall be binding except as set forth in writing
          and signed by its duly authorized representative.
        </ParaText>
        <HeaderText>Terms of Use</HeaderText>
        <ParaText>
          The website www.kleio.me (the "Site") is published and maintained by
          kleio Innovation Labs Pvt. Ltd. ("Company"), existing in accordance
          with the Companies Act of India. When you access, browse or use this
          Site, you accept, without limitation or qualification, the terms and
          conditions set forth herein. When you access any of the sub-Sites
          (whether belonging to an 'associate' of the Company or otherwise)
          through this Site, then such sub-Sites may have its own terms and
          conditions, which are specific to such sub-Sites. These Terms and
          Conditions of use and any additional terms posted on this Site
          together constitute the entire agreement between the Company and you
          with respect to your use of this Site.
        </ParaText>
        <HeaderText>Site and Its Contents</HeaderText>
        <ParaText>
          This Site is only for your personal use. You shall not distribute,
          exchange, modify, sell or transmit anything you copy from this Site,
          including any text, images, audio and video, for any business,
          commercial or public purpose This site is for consumer use only.
        </ParaText>
        <HeaderText>Ownership</HeaderText>
        <ParaText>
          All materials on this Site, including audio, images, software, text,
          icons and such like (the "Content"), are protected by copyright under
          international conventions and copyright laws. You cannot use the
          Content for any purpose, except as specified herein. You agree to
          follow all instructions so provided on this Site limiting the way you
          may use the Content. There are a number of proprietary logos, service
          marks and trademarks found on this Site whether owned/used by the
          Company or any other third party. By displaying them on this Site, the
          Company is not granting you any license to utilize those proprietary
          logos, service marks, or trademarks. Any unauthorized use of the
          Content may violate copyright laws, trademark laws, the laws of
          privacy and publicity, and civil and criminal statutes. You may
          download such copy/copies of the Content to be used only by you for
          your personal use.
        </ParaText>
        <HeaderText>Links to Third-party Websites</HeaderText>
        <ParaText>
          This Site may contain links to websites operated by parties other than
          the Company. The Company does not control such Sites and is not
          responsible for their contents. the Company's inclusion of hyperlinks
          to such Sites does not imply any endorsement of the material on such
          Sites or any association with their operators. If you decide to access
          any of the third party Sites linked to this Site, you do so entirely
          at your own risk.
        </ParaText>
        <HeaderText>Outside Links</HeaderText>
        <ParaText>
          This website may contain links to other websites that are not
          controlled by us. We are not responsible for the privacy practices and
          policies of any website other than our own.
        </ParaText>
        <HeaderText>Transmitted Material</HeaderText>
        <ParaText>
          Internet transmissions are never completely private or secure. You
          understand that any message or information you send to this Site may
          be read or intercepted by others unless there is a special notice that
          a particular message (for example, credit card/debit card/bank
          information) is encrypted (send in code). Sending a message to the
          Company does not cause the Company to have any special responsibility
          to you. The copyright in the contents of this Site belongs to the
          Company. Accordingly, the Company reserves all rights. Copying of part
          or all the content of this Site without permission of the Company is
          prohibited except to the extent that such copying/printing is
          necessary for the purposes of availing of the services.
        </ParaText>

        <HeaderText>Bill Payments</HeaderText>
        <ParaText>
          In order to use the bill payments service or any other Kpay* Service,
          You will need to obtain access to the World Wide Web or the Internet,
          either on a computer or on any other device that can access web-based
          content, and You will also need to pay any service fees associated
          with such access. In addition, You must have all equipment necessary
          to make such connection to the World Wide Web or the Internet,
          including a computer and a modem or any other set of access devices. 
          In using the bill payment service, You agree to:
          <ul style={{ align: "left" }}>
            <li>
              <BulletText>{"\u2B24"}</BulletText>provide true, accurate, current
              and complete information about Yourself (“Registration Data”),
              Your payment account details (“Payment Data”), Your biller details
              (“Biller Data”); and
            </li>
            <li>
              <BulletText>{"\u2B24"}</BulletText>maintain and promptly update
              the Registration Data, Payment Data and Biller Data to keep it
              true, accurate, current and complete at all times. If You provide
              any information that is untrue, inaccurate, not current or
              incomplete, or Kpay* has reasonable grounds to suspect that such
              information is untrue, inaccurate, not current or incomplete,
              Kleio* has the right to suspend or terminate Your account and
              refuse any and all current or future use of the Kleio*
              Services/Kleio* Platform (or any portion thereof). The term biller
              includes telecom operators.
            </li>
          </ul>
        </ParaText>
        <HeaderText>Bus Tickets</HeaderText>
        <ParaText>
          .Kpay* is only a bus ticket agent. It does not operate buses or offers
          bus transport services. In order to provide a comprehensive choice of
          bus operators, departure times and prices to customers, Kpay* has tied
          up with many bus operators and service providers. .Kpay*’s advice to
          customers is to choose the bus operators who they are aware of and
          whose service they are comfortable with. .Kpay*’s responsibilities
          include:
          <ul>
            <li>
              <BulletText>{"\u2B24"}</BulletText>issuing a valid ticket (a
              ticket that will be accepted by the bus operator) for its network
              of bus operators;
            </li>
            <li>
              <BulletText>{"\u2B24"}</BulletText>providing refund and support in
              the event of cancellation; and
            </li>
            <li>
              <BulletText>{"\u2B24"}</BulletText>providing customer support and
              information in case of any delays/inconvenience.
            </li>
          </ul>
        </ParaText>
        <HeaderText>Refund Policy</HeaderText>
        <ParaText>
          All sales of prepaid recharge on the Kpay* Platform are final with no
          refund or exchange permitted. You are responsible for the mobile
          number or DTH account number for which You purchase the prepaid
          recharge and all charges that result from those purchases. You are
          also responsible for the information relating to data card and
          toll-tag recharge and all charges that result from those purchases.
          Kpay* is not responsible for any purchase of prepaid recharge for an
          incorrect mobile number or DTH account number or incorrect toll or
          data card information. However, if in a transaction performed by You
          on the Kpay* Platform, money has been charged to Your card or bank
          account and a recharge is not delivered within 24 hours of the
          completion of the transaction, then You shall inform us by sending an
          e mail to our customer services e mail address mentioned on the
          ‘Contact Us’ page on the Kpay* Platform. Please include in the e-mail
          the following details – the mobile number (or DTH account number or
          data card or toll-tag information), operator name, recharge value,
          transaction date and order number. Kpay* will investigate the incident
          and, if it is found that money was indeed charged to Your card or bank
          account without delivery of the recharge, then You will be refunded
          the money.
        </ParaText>
        <HeaderText>Cancellation Policy</HeaderText>
        <ParaText>
          Cancellation is subject to the policy and you may cancel your order
          only if the cancellation policy of your service allows you to cancel
          it. You must read the cancellation policy before placing an order.
        </ParaText>
        <HeaderText>Shipping Order and Cancellation Policy</HeaderText>
        <ParaText>
          This business does not provide any kind of shipping.
        </ParaText>

        <HeaderText>Suspicious/ Fraudulent Transaction</HeaderText>
        <ParaText>
          Refund of Transactions which is Marked as Suspicious/ Fraudulent Would
          be treated on a very Stringent manner also the Refund would be
          Initiated for Such transactions after Deducting the Convenience fee of
          the platform
        </ParaText>

        <HeaderText>Contact Details</HeaderText>
        <LastText>
          If you have any questions regarding this policy, please email us at
          <MailText href="mailto:contact@kleio.me">contact@kleio.me</MailText>
        </LastText>
      </Wrapper>
    </StyledSection>
  );
};

export default ErrorPage;
