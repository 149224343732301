import { createGlobalStyle } from "styled-components";
import NunitoReg from "./assets/fonts/Nunito/Nunito-Regular.ttf";
import LatoReg from "./assets/fonts/Lato/Lato-Regular.ttf";
import BebasReg from "./assets/fonts/Bebas_Neue/BebasNeue-Regular.ttf";
import DroidSans from "./assets/fonts/droid-sans/DroidSans.ttf";
import Poppins from "./assets/fonts/Poppins/Poppins-Regular.ttf";
import Roboto from "./assets/fonts/Roboto/Roboto-Regular.ttf";

const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: "Nunito";
  src: url(${NunitoReg});
}
@font-face {
  font-family: "Lato";
  src: url(${LatoReg});
}
@font-face {
  font-family: "Bebas Neue";
  src: url(${BebasReg});
}
@font-face {
  font-family: "Droid Sans";
  src: url(${DroidSans});
}
@font-face {
  font-family: "Poppins";
  src: url(${Poppins});
}
@font-face {
  font-family: "Roboto";
  src: url(${Roboto});
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	user-select: none;

}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	/* 121212 */
	background-color:${(props) => props.theme.colors.onBackground};
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

a {
	display: inline-block;
	text-decoration: none;
}
a:hover {
	color: inherit;
}

`;

export default GlobalStyle;
