import React, { useContext } from "react";
//importing data
import jobs from "../../data/jobListings";
import ErrorPage from "../Error/ErrorPage";

//importing context
import JobContext from "../../store/JobContext";

//import styles
import {
  CareerTopSection,
  Wrapper,
  Title,
  TopImageWrapper,
  TopImage,
  SubTitle,
  JobDescriptionWrapper,
  JobTitle,
  JobDetailWrapper,
  JobContentWrapper,
  JobDetailTitle,
  JobDetailSubTitle,
  DescriptionWrapper,
  Description,
  ButtonWrapper
} from "./styles";

// import assets
import careersTop from "../../assets/careers/careersTop.png";

// import components
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";

const JobDescriptionpage = props => {
  const jobCtx = useContext(JobContext);
  const jobId = props.match.params.jobId;

  const data = jobs.find(job => job.jobId === jobId) || {};
  console.log(data);

  const jobData = [
    {
      title: "Position type",
      subTitle: data.jobType
    },
    {
      title: "Location",
      subTitle: data.Location
    },
    {
      title: "Work Experience",
      subTitle: data.Experience
    }
  ];

  const jobAppliedhandler = ( selectedJobId, selectedJobTitle) => {
    jobCtx.onJobSelect(selectedJobId, selectedJobTitle);
  };

  if (!Object.keys(data).length) return <ErrorPage />;

  return (
    <>
      {/* banner starts */}
      <CareerTopSection>
        <Wrapper>
          <Title>CAREERS</Title>
          <TopImageWrapper>
            <TopImage src={careersTop} />
          </TopImageWrapper>
        </Wrapper>
      </CareerTopSection>
      {/* banner ends */}
      <JobDescriptionWrapper>
        <Wrapper>
          {/* Job details start */}
          <SubTitle>JOB DETAILS</SubTitle>
          <JobTitle>{data.jobTitle?.toUpperCase()}</JobTitle>
          <JobDetailWrapper>
            {jobData.map((job, index) => {
              return (
                <JobContentWrapper>
                  <JobDetailTitle>{job.title}</JobDetailTitle>
                  <JobDetailSubTitle>{job.subTitle}</JobDetailSubTitle>
                </JobContentWrapper>
              );
            })}
          </JobDetailWrapper>
          {/* Job details ends */}
          {/* Roles and responsibilities start */}
          <DescriptionWrapper>
            <JobDetailTitle>Roles and responsibilities</JobDetailTitle>
            <Description dangerouslySetInnerHTML={{ __html: data.Role }} />
          </DescriptionWrapper>
          {/* Roles and responsibilities end */}
          {/* Apply button starts */}
          <ButtonWrapper>
            <PrimaryButton
              text="APPLY NOW"
              secondary
              to="/careers/apply"
              handleClick ={() => jobAppliedhandler(data.jobId, data.jobTitle)}
            />
          </ButtonWrapper>
          {/* Apply Button ends */}
        </Wrapper>
      </JobDescriptionWrapper>

      {/* <StyledSection>
      <Wrapper>
        <JobDetailWrapper>
          <StyledTitle>Job Description</StyledTitle>
          <StyledSubTitle>
            <Bold>Job ID </Bold> : {data.jobId}
          </StyledSubTitle>
          <StyledSubTitle>
            <Bold>Job Title </Bold> : {data[0].jobTitle}
          </StyledSubTitle>
          <StyledSubTitle>
            <Bold>Experience </Bold> : {data[0].Experience}
          </StyledSubTitle>
          <StyledSubTitle>
            <Bold>Location</Bold> : {data[0].Location}
          </StyledSubTitle>
          <StyledSubTitle>
            <Bold>Roles and responsibilities </Bold>:
          </StyledSubTitle>
          <StyledSubTitle dangerouslySetInnerHTML={{ __html: data[0].Role }} />

          <StyledNavlink to="/careers/apply">
            <SubmitButton
              onClick={() => jobAppliedhandler(data[0].jobId, data[0].jobTitle)}
            >
              Apply
            </SubmitButton>
          </StyledNavlink>
        </JobDetailWrapper>
      </Wrapper>
    </StyledSection> */}
    </>
  );
};

export default JobDescriptionpage;
