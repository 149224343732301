import styled from "styled-components";
import { Link } from "react-router-dom";

import SecondaryButton from "../../styled-elements/SecondaryButton";

export const Title = styled.h2`
  font-family: ${(props) => props.theme.fonts.DroidSans};
  font-size: 36px;
  line-height: 54px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.appWhite};
  padding-top: 143px;
  width: 320px;
  text-align: center;
  @media (max-width: 768px) {
    margin: 0 auto;
    padding-top: 30px;
    font-size: 20px;
    line-height: 36px;
  }
`;

export const ContentWrapper = styled.div`
  /* height: 100vh; */
  background-color: ${(props) => props.theme.colors.appDark900};
  border-radius: 50px;
  padding: 52px 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
  @media (max-width: 600px) {
    padding: 26px 32px;
  }
`;

export const TextSide = styled.div`
  width: 50%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const JobWrapper = styled.div`
  width: 100%;
  padding: 4%;
`;

export const JobDivider = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.colors.appLightDark100};
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
`;

export const TopText = styled.h3`
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  color: ${(props) => props.theme.colors.appLightDark75};
  margin-top: 120px;
  margin-bottom: 24px;
`;

export const TopRowLeftText = styled.h3`
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  color: ${(props) => props.theme.colors.appWhite};
  @media (max-width: 810px) {
    font-size: 24px;
  }
  @media (max-width: 500px) {
    font-size: 16px;
  }
`;

export const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width: 92%;
  margin-bottom: 32px;
`;

export const BottomRow = styled.div`
  display: flex;
`;

export const ItemImage = styled.img`
  margin-right: 8px;
  @media (max-width: 500px) {
    margin-right: 4px;
  }
`;

export const KnowMoreWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const KnowMoreIcon = styled.img``;

export const BottomItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
  @media (max-width: 500px) {
    margin-right: 12px;
  }
`;

export const BottomItemText = styled.h3`
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${(props) => props.theme.colors.appLightDark100};
  @media (max-width: 810px) {
    font-size: 16px;
  }
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

export const TopRowRightText = styled.h3`
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${(props) => props.theme.colors.appLightDark25};

  @media (max-width: 600px) {
    display: none;
  }
`;

export const TopTitle = styled.h2`
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-top: 24px;
  color: ${(props) => props.theme.colors.appWhite};
  @media (max-width: 810px) {
    font-size: 36px;
  }
  @media (max-width: 500px) {
    font-size: 28px;
  }
`;

export const CareersDesc = styled.p`
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${(props) => props.theme.colors.appLightDark25};
  margin: 24px 0px;
  width: 92%;

  @media (max-width: 810px) {
    font-size: 16px;
    width: 100%;
  }
`;

export const ViewAllButton = styled.button`
  background-color: ${(props) => props.theme.colors.appYellow};
  border-radius: 100px;
  padding: 16px 24px;
  margin-bottom: 24px;

  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${(props) => props.theme.colors.appDark900};
  text-align: center;
  cursor: pointer;
  border: none;
`;

export const JobSide = styled.div`
  width: 50%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const SubTitle = styled.h3`
  font-family: ${(props) => props.theme.fonts.DroidSans};
  color: ${(props) => props.theme.colors.appYellow};
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
  margin-top: 4px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const Jobs = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const JobCard = styled(Link)`
  border: 1px solid white;
  border-radius: 8px;
  width: 200px;
  margin: 15px;

  @media (max-width: 800px) {
    width: 190px;
  }

  @media (max-width: 768px) {
    width: 75%;
    margin: 20px auto;
  }
`;

export const JobContent = styled.div`
  padding: 0px 20px;
  padding-top: 36px;
  padding-bottom: 42px;
`;

export const AvatarImageWrapper = styled.div`
  text-align: center;
`;

export const AvatarImage = styled.img``;

export const JobTitle = styled.h3`
  font-family: ${(props) => props.theme.fonts.Lato};
  color: ${(props) => props.theme.colors.appWhite};
  font-size: 20px;
  line-height: 36px;
  font-weight: 700;
  margin-top: 12px;
  text-align: center;
`;

export const JobType = styled.h3`
  font-family: ${(props) => props.theme.fonts.Lato};
  color: ${(props) => props.theme.colors.appWhite};
  text-align: center;
`;

export const ApplyTextWrapper = styled.div`
  text-align: center;
`;

export const ApplyText = styled.h3`
  font-family: ${(props) => props.theme.fonts.Lato};
  color: ${(props) => props.theme.colors.appYellow};
  margin-top: 12px;
  text-decoration: underline;
  text-underline-position: under;
`;

export const ArrowImage = styled.img``;

export const StyledSection = styled.section`
  // background-color: rgba(35, 37, 43, 1);
  padding-bottom: 112px;
`;

export const Wrapper = styled.div`
  ${(props) => props.theme.wrapper}
`;

export const Styledh1 = styled.h1`
  color: ${(props) => props.theme.colors.appWhite};
  font-family: ${(props) => props.theme.fonts.Lato};
  font-size: 1.2rem;
  text-align: center;
`;

export const ViewJobsButton = styled.button`
  ${SecondaryButton};
  margin: 0 auto;
  cursor: pointer;
  display: block;
  padding: 16px 0px;
  width: 200px;
  /* border: none; */
`;

export const ButtonWrapper = styled.div`
  margin: 0 auto;
  margin: 50px 20px;
  @media (max-width: 768px) {
    width: 50%;
    margin: 0 auto;
    margin-top: 28px;
    margin-bottom: 28px;
    min-width: 300px;
  }
`;
