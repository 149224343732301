import React from "react";
import { Slide } from "react-awesome-reveal";

//import assets
import left from "../../assets/Products/imageKpayHome.png";
import middle from "../../assets/Products/imageKpayOnboarding.png";
import right from "../../assets/Products/gifKpaySuccessCropped.gif";

// import styles
import {
  Wrapper,
  ImagesWrapper,
  LeftWrapper,
  LeftImage,
  MiddleWrapper,
  MiddleImage,
  RightWrapper,
  RightImage,
  Title,
  ButtonWrapper,
  ButtonDiv,
  ButtonText,
} from "./styles";

const kPayPage = () => {
  return (
    <Wrapper>
      <ImagesWrapper>
        <Slide bottom delay={500} duration={1500}>
          <LeftWrapper>
            <LeftImage src={left} />
          </LeftWrapper>
        </Slide>
        <Slide bottom delay={500} duration={1000}>
          <MiddleWrapper>
            <MiddleImage src={middle} />
          </MiddleWrapper>
        </Slide>
        <Slide bottom delay={500} duration={1500}>
          <RightWrapper>
            <RightImage src={right} />
          </RightWrapper>
        </Slide>
      </ImagesWrapper>
      <Title>Kpay- Payments App</Title>
      <ButtonWrapper>
        <ButtonDiv>
          <ButtonText>Coming Soon</ButtonText>
        </ButtonDiv>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default kPayPage;
