import React, { useState, useLayoutEffect, useEffect, useRef } from "react";

// import package
import Slider from "react-slick";
import { Link } from "react-router-dom";

// import assets
import kpay from "../../assets/LandingSections/kpayMockup.png";
import inBima from "../../assets/LandingSections/inbimaMockup.png";
import Rupee from "../../assets/Products/Rupee.svg";
import Star from "../../assets/Products/Star.svg";
import Downloads from "../../assets/Products/Downloads.svg";
import kpaisaMain from "../../assets/Products/KpaisaHero.png";
import kpaisaSub from "../../assets/Products/kpaisaSub.png";
import fitlogMain from "../../assets/Products/FitlogHero.png";
import fitlogSub from "../../assets/Products/WorkoutCard.png";
import humanPlaceholder from "../../assets/about/humanPlaceholder.svg";

import {
  Wrapper,
  Title,
  // SubTitle,
  CardWrapper,
  ProductCard,
  ProductImageWrapper,
  ProductImage,
  ProductDescription,
  ProductTitle,
  ButtonWrapper,
  StyledSection,
  StyledNavLink,
  TopRowSideContentSingle,
  // new styles
  ContentWrapper,
  LeftContent,
  RightContent,
  ProductsTitle,
  TopRowWrapper,
  MainTitle,
  TopRowSideContent,
  RowItemWrapper,
  RowText,
  RowImage,
  ProductDesc,
  LearnMoreButton,
  MainImage,
  SubImage,
  RightInnerWrapper,
  TestimonialTitle,
  TestimonialsWrapper,
  TestimonialBigTitle,
  TestimonialSubTitle,
  ReviewCard,
  ReviewText,
  ReviewName,
  ReviewDate,
  RightContentWrapper,
  RightContentRightWrapper,
  LearnMoreButtonWrapper,
  LeftContentTest,
  RightContentTest,
  MarqueeDown,
  MarqueeUp,
  ReviewsWrapper,
  ProductWrapper,
  InvisibleBlock,
  ReviewImg,
  ReviewTopWrapper,
} from "./styles";

// import utils
import useOnScreen from "../../utils/useOnScreen";

// import components
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";

// import styles
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import SectionNumber from "../../components/SectionNumber/SectionNumber";

const ReviewOne = [
  {
    name: "Shivanshu Pandey",
    review:
      "Easy to use it is simple and it has intresting features. great app",
    date: "14 August 2023",
  },
  {
    name: "Neelam Mishra",
    review:
      "Easy to use app for buying Digital Gold Very Good Design easy to use. It's very simple to use",
    date: "2 July 2023",
  },
  {
    name: "Meetu Bhardwaj",
    review:
      "Quite easy and seamless to use and best app to invest in gold! Highly recommended!",
    date: "30 June 2023",
  },
  {
    name: "Ayushi Rajput",
    review:
      "The customer support team is top-notch , always there to assist 👏📞",
    date: "23 July 2023",
  },
  {
    name: "Umara Siddiqui",
    review: "This app has transformed the way I approach investments 💡💰",
    date: "22 July 2023",
  },
  {
    name: "Shivanshu Pandey",
    review:
      "Easy to use it is simple and it has intresting features. great app",
    date: "14 August 2023",
  },
  {
    name: "Neelam Mishra",
    review:
      "Easy to use app for buying Digital Gold Very Good Design easy to use. It's very simple to use",
    date: "2 July 2023",
  },
  {
    name: "Meetu Bhardwaj",
    review:
      "Quite easy and seamless to use and best app to invest in gold! Highly recommended!",
    date: "30 June 2023",
  },
];

const ReviewTwo = [
  {
    name: "Piyush Sharma",
    review: "I love how user-friendly and intuitive this app is! 👍💸",
    date: "22 July 2023",
  },
  {
    name: "Heth Jain",
    review:
      "Easy to use and clean UI. Wonderful experience in using the app. Good job!",
    date: "29 March 2023",
  },
  {
    name: "Yash Sharma",
    review:
      "This app makes investing so much more accessible and enjoyable 💵💻",
    date: "22 July 2023",
  },
  {
    name: "Hatvi Ranawat",
    review:
      "very smoothly working,great application.A positive response from my end👍",
    date: "5 July 2023",
  },
  {
    name: "Himanshi Sharma",
    review: "Finally, an app that simplifies my investment journey 💪📊",
    date: "22 July 2023",
  },
  {
    name: "Piyush Sharma",
    review: "I love how user-friendly and intuitive this app is! 👍💸",
    date: "22 July 2023",
  },
  {
    name: "Heth Jain",
    review:
      "Easy to use and clean UI. Wonderful experience in using the app. Good job!",
    date: "29 March 2023",
  },
  {
    name: "Yash Sharma",
    review:
      "This app makes investing so much more accessible and enjoyable 💵💻",
    date: "22 July 2023",
  },
];

const ProductPage = () => {
  const productRef = useRef();
  const isVisible = useOnScreen(productRef);
  const [scrollActionStart, setScrollActionStart] = useState(false);

  const settings = {
    dots: true,
    infinite: false,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settingsParallel = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    vertical: true,
    pauseOnHover: false,
    rtl: true,
    arrows: false,
  };
  const settingsParallelRight = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    vertical: true,
    pauseOnHover: false,
    arrows: false,
  };

  const slider = useRef();

  useEffect(() => {
    console.log(isVisible, "is visible");

    const handleWheelScroll = (e) => {
      if (isVisible && !scrollActionStart) {
        console.log("set to true");
        setScrollActionStart(true);
      }

      // else{
      //   console.log("set to false");
      //   setScrollActionStart(false);
      // }

      if (scrollActionStart) {
        console.log(e.wheelDelta, "delta value");
        slide(e.wheelDelta);
      }
    };

    window.addEventListener("wheel", handleWheelScroll);

    return () => {
      window.removeEventListener("wheel", handleWheelScroll);
    };
  }, [isVisible, scrollActionStart]);

  const slide = (y) => {
    y < 0 ? slider.current.slickNext() : slider.current.slickPrev();
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  const [width, height] = useWindowSize();

  const RowItem = ({ icon, text }) => {
    return (
      <RowItemWrapper>
        <RowImage src={icon} />
        <RowText>{text}</RowText>
      </RowItemWrapper>
    );
  };

  const Kpaisa = () => {
    return (
      <ContentWrapper>
        <LeftContent>
          <div style={{ width: width > 810 ? "92%" : "100%" }}>
            {width >= 812 && (
              <SectionNumber number="02" text="Our Products" darkBg={true} />
            )}
            <TopRowWrapper>
              <MainTitle>kPaisa</MainTitle>
              <TopRowSideContent>
                <RowItem icon={Downloads} text="10k+ Downloads" />
                <RowItem icon={Rupee} text="50L+ worth transactions" />
                <RowItem icon={Star} text="4.1/5 Star Rating" />
              </TopRowSideContent>
            </TopRowWrapper>
            <ProductDesc>
              Experience the future of financial management with kPaisa.
              Designed to streamline and simplify your financial processes,
              kPaisa empowers individuals, businesses, and institutions to take
              control of their finances like never before.
            </ProductDesc>
            <LearnMoreButtonWrapper>
              <Link
                to={{ pathname: "https://kpaisa.in/" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LearnMoreButton>Learn More</LearnMoreButton>
              </Link>
            </LearnMoreButtonWrapper>
          </div>
        </LeftContent>
        <div>
          {width <= 811 && (
            <SectionNumber number="02" text="Our Products" darkBg={true} />
          )}
          <RightContent>
            <RightInnerWrapper>
              <MainImage src={kpaisaMain} />
              {/* <SubImage src={kpaisaSub} /> */}
            </RightInnerWrapper>
          </RightContent>
        </div>
      </ContentWrapper>
    );
  };
  const Fitlog = () => {
    return (
      <ContentWrapper>
        <LeftContent>
          <div style={{ width: width > 810 ? "92%" : "100%" }}>
            {width >= 812 && (
              <SectionNumber number="02" text="Our Products" darkBg={true} />
            )}

            <TopRowWrapper style={{justifyContent: "flex-start"}}>
              <MainTitle>FitLog</MainTitle>
              <TopRowSideContentSingle>
                <RowItem icon={Star} text="4.1/5 Star Rating" />
              </TopRowSideContentSingle>
            </TopRowWrapper>
            <ProductDesc>
              Elevate Your Health Journey – Your All-in-One Wellness Companion!
              Track activities, calories, and more effortlessly. Stay hydrated, never miss a pill, 
              and achieve your fitness goals with ease. It's time to live your healthiest life!"
            </ProductDesc>
            <LearnMoreButtonWrapper>
              <Link
                to={{
                  pathname:
                    "https://play.google.com/store/apps/details?id=com.kleio.fitlog",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LearnMoreButton>Learn More</LearnMoreButton>
              </Link>
            </LearnMoreButtonWrapper>
          </div>
        </LeftContent>

        <div>
          {width <= 811 && (
            <SectionNumber number="02" text="Our Products" darkBg={true} />
          )}
          <RightContent>
            <RightInnerWrapper>
              <MainImage src={fitlogMain} />
              {/* <SubImage src={fitlogSub} fitlog={true} /> */}
            </RightInnerWrapper>
          </RightContent>
        </div>
      </ContentWrapper>
    );
  };

  return (
    <StyledSection id="products">
      <Wrapper>
        <ProductWrapper>
          <Slider {...settings} ref={slider}>
            <Kpaisa />
            <Fitlog />
          </Slider>
          <InvisibleBlock ref={productRef} />
        </ProductWrapper>
        <TestimonialsWrapper>
          <LeftContentTest>
            <SectionNumber number="03" text="Testimonials" darkBg={false} />

            <div style={{ width: "95%" }}>
              <TestimonialBigTitle>
                We believe in the power of community
              </TestimonialBigTitle>
              <TestimonialSubTitle>
                At Kleio, our mission is to redefine the financial landscape
                through innovation, technology, and a shared commitment to
                excellence. As a Startup-India recognized organization, we're
                driven by the belief that finance should be a catalyst.
              </TestimonialSubTitle>
            </div>
          </LeftContentTest>
          <RightContentTest>
            <RightContentWrapper>
              <MarqueeDown direction={width > 1024 ? "down" : "right"}>
                <ReviewsWrapper>
                  {ReviewOne.map((item, index) => {
                    return (
                      <ReviewCard key={item.name + index}>
                        <ReviewTopWrapper>
                          <ReviewImg src={humanPlaceholder} />
                          <ReviewName>{item.name}</ReviewName>
                        </ReviewTopWrapper>
                        <ReviewText>{item.review}</ReviewText>
                        <ReviewDate>{item.date}</ReviewDate>
                      </ReviewCard>
                    );
                  })}
                </ReviewsWrapper>
              </MarqueeDown>
            </RightContentWrapper>
            <RightContentRightWrapper>
              <MarqueeUp direction={width > 1024 ? "up" : "left"}>
                <ReviewsWrapper>
                  {ReviewTwo.map((item, index) => {
                    return (
                      <ReviewCard key={item.name + index}>
                        <ReviewTopWrapper>
                          <ReviewImg src={humanPlaceholder} />
                          <ReviewName>{item.name}</ReviewName>
                        </ReviewTopWrapper>
                        <ReviewText>{item.review}</ReviewText>
                        <ReviewDate>{item.date}</ReviewDate>
                      </ReviewCard>
                    );
                  })}
                </ReviewsWrapper>
              </MarqueeUp>
            </RightContentRightWrapper>
          </RightContentTest>
        </TestimonialsWrapper>
      </Wrapper>
    </StyledSection>
  );
};

export default ProductPage;
