import React from "react";

//importing sections of our landing page
import HomePage from "../../sections/Home/HomePage";
import WhoAreWe from "../../sections/WhoAreWe/WhoAreWeSection";
import AboutUsPage from "../../sections/AboutUs/AboutUsPage";
import ContactUsPage from "../../sections/ContactUs/ContactUsPage";
import ProductPage from "../../sections/Product/Productpage";
import CareersPage from "../../sections/Careers/CareersPage";

const LandingPage = () => {
  return (
    <>
      <HomePage />
      <AboutUsPage />
      <ProductPage />
      <CareersPage />
    </>
  );
};

export default LandingPage;
