import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

//import assets
import dotBorder from "../../assets/Home/dotBorder.svg";

export const AboutSection = styled.section`
  /* position: absolute; */
`;

export const Wrapper = styled.div`
  ${(props) => props.theme.wrapper};
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const TitleTop = styled.h3`
  text-align: center;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  color: #bebebe;
  padding-top: 5%;
  @media (max-width: 810px) {
    padding-top: 15%;
  }
  @media (max-width: 500px) {
    padding-top: 20%;
  }
`;

export const Caption = styled.h2`
  font-family: Roboto;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  text-align: center;
  margin-top: 15px;
  color: ${(props) => props.theme.colors.appWhite};
  width: 40%;
  align-self: center;
  padding-bottom: 20%;

  @media (max-width: 810px) {
    font-size: 32px;
    width: 70%;
    padding-bottom: 30%;
  }
  @media (max-width: 500px) {
    font-size: 27px;
    width: 90%;
    padding-bottom: 50%;
  }
`;

export const WhiteTopSection = styled.div`
  padding: 52px;
  position: relative;
  z-index: 1;
  top: -7rem;
  // bottom: 20px;
  background-color: ${(props) => props.theme.colors.appWhite};
  border-radius: 50px;
`;

export const OurStoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 24px;
  margin-bottom: 52px;

  @media (max-width: 810px) {
    flex-direction: column;
  }
`;

export const TextSide = styled.div`
  width: 50%;
  @media (max-width: 810px) {
    width: 100%;
  }
`;

export const DescriptionSide = styled.div`
  width: 50%;

  @media (max-width: 810px) {
    width: 100%;
  }
`;

export const Text = styled.h2`
  font-family: Roboto;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  color: #171717;
`;

export const Description = styled.p`
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #686868;
  @media (max-width: 810px) {
    margin-top: 24px;
  }
`;

export const Title = styled.h2`
  font-family: ${(props) => props.theme.fonts.Lato};
  color: ${(props) => props.theme.colors.appWhite};
  font-weight: 700;
  text-align: center;
  font-size: 56px;
  line-height: 84px;
  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 54px;
    /* margin-bottom: 25px; */
  }
`;

export const StoryTitle = styled.h3`
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.appDark600};
  margin-bottom: 24px;
`;

export const StoryImage = styled.img`
  ${(props) =>
    props.startupIndia &&
    css`
      width: 50%;
    `}
  ${(props) =>
    props.kpaisaImg &&
    css`
      width: 30%;
    `}
`;

export const StoryDate = styled.h3`
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.appDark600};
  margin-bottom: 8px;
`;

export const StyledTimeline = styled.div`
  padding-bottom: 106px;
  .vertical-timeline * {
    transform: translate(0.5rem, 0.6rem);
  }
  .vertical-timeline-element-icon {
    box-shadow: none;
    position: relative;
    display: inline-block;
    z-index: 1;
    /* background-color: ${(props) => props.theme.colors.appDark400}; */
    background-color: transparent;
  }
  .vertical-timeline-element-content {
    box-shadow: none;
  }

  .foundation .vertical-timeline-element-content {
    transform: translate(5rem, -1.5rem);
  }

  .startup,
  .kPaisa {
    .vertical-timeline-element-icon img {
      transform: translate(-12rem);
    }

    .vertical-timeline-element-content {
      transform: translate(4rem, -4rem);
    }
  }

  .vertical-timeline::before {
    background: url(${dotBorder});
    z-index: 1;
  }

  @media (max-width: 1169px) {
    .foundation .vertical-timeline-element-content {
      transform: translate(6rem, -3rem);
    }
    .foundation .vertical-timeline-element-content img {
      width: 30%;
    }
    .vertical-timeline-element-icon img {
      transform: translateX(-0.15rem);
    }

    .startup,
    .kPaisa {
      .vertical-timeline-element-icon img {
        transform: translate(-12rem);
      }

      .vertical-timeline-element-content {
        transform: translate(4rem, -4rem);
      }
    }
  }

  @media (max-width: 500px) {
    .foundation .vertical-timeline-element-content {
      transform: translate(1rem, -3rem);
    }

    .foundation .vertical-timeline-element-icon img {
      width: 60px;
    }
  }
`;

export const AboutContainer = styled.section`
  background-color: ${(props) => props.theme.colors.appDark900};
`;

export const TopImageWrapper = styled.div`
  /* z-index: 1; */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -66px;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const TopImage = styled.img`
  width: 400px;
  height: auto;
  @media (max-width: 768px) {
    width: 70%;
    min-width: 280px;
    height: auto;
  }
`;

export const RowWrapper = styled.section``;

export const SubTitle = styled.h3`
  font-family: ${(props) => props.theme.fonts.Lato};
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.appBlack};
  text-align: center;
  margin-top: 115px;
  ${(props) =>
    props.second &&
    css`
      margin-top: 80px;
    `}
  @media (max-width: 768px) {
    text-align: center;
    font-size: 30px;
    line-height: 45px;
  }
`;

export const ReadLink = styled(NavLink)`
  text-decoration: underline;
  font-family: ${(props) => props.theme.fonts.Lato};
  font-size: 20px;
  width: 100%;
  text-align: center;
  line-height: 36px;
  font-weight: 700;
  margin-top: 24px;
  text-underline-position: under;
  &:link {
    color: ${(props) => props.theme.colors.appBlue};
  }
  &:visited {
    color: ${(props) => props.theme.colors.appBlue};
  }
  &:hover {
    color: ${(props) => props.theme.colors.appBlue};
  }
  &:active {
    color: ${(props) => props.theme.colors.appBlue};
  }
`;

export const ValuesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 36px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ValueWrapper = styled.div`
  max-width: 250px;
  margin-bottom: 13px;
  @media (max-width: 768px) {
    margin: 0 auto;
  }
`;

export const ValueImageWrapper = styled.div`
  text-align: center;
`;

export const ValueImage = styled.img`
  width: 80px;
  height: auto;
`;

export const ValueName = styled.h4`
  text-align: center;
  font-family: ${(props) => props.theme.fonts.Lato};
  font-size: 20px;
  line-height: 36px;
  font-weight: 900;
`;

export const ValueDescription = styled.h5`
  text-align: center;
  font-family: ${(props) => props.theme.fonts.Lato};
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.textLight};
  margin-top: 4px;
`;

export const MeetContainer = styled.section`
  margin-top: 67px;
  background-color: rgba(80, 100, 173, 0.1);
`;

export const TeamTitle = styled.h3`
  font-family: ${(props) => props.theme.fonts.DroidSans};
  font-size: 36px;
  line-height: 54px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.appBlack};
  text-align: center;
  margin-top: 36px;
  padding-top: 36px;
`;

export const TeamMembers = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-bottom: 43px;
  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const TeamMember = styled.div`
  margin-top: 31px;
  ${(props) =>
    props.second &&
    css`
      margin-top: 66px;
    `}
  @media (max-width: 768px) {
    ${(props) =>
      props.second &&
      css`
        margin-top: 31px;
      `}
  }
`;

export const MemberImageWrapper = styled.div``;

export const MemberImage = styled.img`
  width: 240px;
  height: auto;
`;

export const MemberName = styled.h4`
  font-family: ${(props) => props.theme.fonts.Lato};
  font-size: 20px;
  line-height: 30px;
  font-weight: 900;
  color: ${(props) => props.theme.colors.appBlack};
  padding-left: 10px;
  margin-top: 3px;
`;

export const MemberRole = styled.h5`
  font-family: ${(props) => props.theme.fonts.Lato};
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.textLight};
  padding-left: 10px;
  margin-top: 4px;
`;
