import { css } from "styled-components";

const InputText = css`
  font-family: ${props => props.theme.fonts.Lato};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  resize: none;

  /* Grey / 04 */
  color: ${props => props.theme.colors.appGrey4};
  ::placeholder {
    font-family: ${props => props.theme.fonts.Lato};
  }
  &:focus {
    outline: none;
  }
`;
export default InputText;
