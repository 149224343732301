import React from "react";

import "./styles.css";

const HeroSpiral = () => {
  return (
    <div>
      {/* <div class="center"></div> */}
      <article class="ring1">
        <div></div>
      </article>
      <article class="ring2">
        <div></div>
      </article>
      <article class="ring3">
        <div></div>
      </article>
    </div>
  );
};

export default HeroSpiral;
