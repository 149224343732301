import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import ourJourneyBg from "../../assets/LandingSections/ourJourney.jpg";

export const Wrapper = styled.div`
  ${(props) => props.theme.wrapper};

  animation: zoom-in-zoom-out 1s ease;

  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(0.8, 0.8);
    }
    50% {
      transform: scale(0.9, 0.9);
    }

    100% {
      transform: scale(1, 1);
    }
  }
`;

export const StoryContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  @media (max-width: 810px) {
    flex-direction: column;
    justify-content: center;
  }
  @media (max-width: 390px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const StoryLeft = styled.div`
  width: 45%;
  text-align: left;
  flex-direction: column;
  @media (max-width: 810px) {
    width: 100%;
    padding-right: 0px;
  }
`;

export const AboutTitle = styled.h3`
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  color: ${(props) => props.theme.colors.appDark400};
  margin-top: 120px;
`;

export const MainTitle = styled.h2`
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-top: 24px;
  width: 68%;
  @media (max-width: 1024px) {
    font-size: 42px;
  }
  @media (max-width: 810px) {
    font-size: 36px;
  }
  @media (max-width: 390px) {
    font-size: 28px;
  }
`;

export const Description = styled.h4`
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${(props) => props.theme.colors.appDark600};
  margin: 24px 0px;
  @media (max-width: 810px) {
    font-size: 16px;
  }
`;

export const LearnMoreWrapper = styled.div``;

export const LearnMoreText = styled.h4`
  cursor: pointer;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: ${(props) => props.theme.colors.appDark900};
`;

export const Title = styled.h3`
  text-transform: uppercase;
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  font-family: ${(props) => props.theme.fonts.Lato};
  color: ${(props) => props.theme.colors.appBlack};
  .story {
    color: ${(props) => props.theme.colors.appRed};
  }
`;

export const SubTitle = styled.p`
  color: ${(props) => props.theme.colors.textLight};
  font-size: 20px;
  line-height: 36px;
  font-family: ${(props) => props.theme.fonts.Lato};
  font-weight: 100;
  margin-top: 12px;
  width: 90%;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0px 20px;
  }
`;

export const StoryRight = styled.div`
  width: 32%;
  @media (max-width: 810px) {
    width: 100%;
  }
`;

export const TileWrapper = styled.div`
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-bottom: 16px;
  background-color: ${(props) => props.theme.colors.appLightDark50};
  height: 200px;

  ${(props) =>
    props.active &&
    css`
      animation: colorChange 2s infinite ease-in-out 0.6s;
    `}

  @keyframes colorChange {
    to {
      background-color: ${(props) => props.theme.colors.appDark900};
    }
  }

  @media (max-width: 1024px) {
    min-height: 160px;
    /* min-width: 160px; */
  }
  @media (max-width: 500px) {
    min-height: 140px;
    /* min-width: 140px; */
  }
`;

export const TileSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 1rem;
  width: 100%;

  margin-top: 24px;
  @media (max-width: 810px) {
    margin-top: 24px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
  @media (max-width: 600px) {
    margin-top: 24px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

export const SectionNumberWrapper = styled.div`
  margin-top: 52px;
`;

export const TileTitle = styled.h3`
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${(props) => props.theme.colors.appDark700};

  /* ${(props) =>
    props.active &&
    css`
      animation: colorChange 2s infinite ease-in-out 0.6s;
    `}

  @keyframes colorChange {
    to {
      color: ${(props) => props.theme.colors.appWhite};
    }
  } */

  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.colors.appWhite};
    `}

  @media (max-width: 1024px) {
    font-size: 12px;
  }
`;

export const TileSubTitle = styled.h4`
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  color: ${(props) => props.theme.colors.appDark600};

  /* ${(props) =>
    props.active &&
    css`
      animation: colorChange 2s infinite ease-in-out 0.6s;
    `}

  @keyframes colorChange {
    to {
      color: ${(props) => props.theme.colors.appWhite};
    }
  } */

  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.colors.appWhite};
    `}

  @media (max-width: 1024px) {
    font-size: 12px;
  }
`;

export const TileImage = styled.img`
  height: 64px;
  width: auto;
  /* margin: 24px 0px; */
  @media (max-width: 1024px) {
    font-size: 48px;
  }
  @media (max-width: 500px) {
    font-size: 32px;
  }
`;

export const StyledSection = styled.section``;

export const TitleRight = styled.h3`
  font-family: ${(props) => props.theme.fonts.Poppins};
  font-weight: 600;
  font-size: 30px;
  line-height: 60px;
  text-align: center;
  /* margin-top: 10vh; */
  color: ${(props) => props.theme.colors.appWhite};
  padding-left: 10%;
  @media (max-width: 768px) {
    padding-left: 0px;
  }
`;

export const JourneyWrapper = styled.div`
  padding-left: 10%;
  @media (max-width: 768px) {
    padding-left: 0px;
  }
  .verticalTimeline {
    height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .verticalTimeline {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
  }

  .verticalTimeline::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  .vertical-timeline:before {
    left: 21px;
  }
  .vertical-timeline-element-icon {
    left: 4px;
  }
`;

export const IconWrapper = styled.div``;

export const Icon = styled.img``;

export const TimelineTitle = styled.h3`
  font-size: 30px;
  line-height: 45px;
  color: ${(props) => props.theme.colors.appWhite};
  font-family: ${(props) => props.theme.fonts.Poppins};
  font-weight: 600;
`;
export const TimelineContent = styled.h4`
  font-size: 18px;
  line-height: 27px;
  color: ${(props) => props.theme.colors.appWhite};
  font-family: ${(props) => props.theme.fonts.Poppins};
  font-weight: 400;
`;

export const ReadLink = styled(NavLink)`
  text-decoration: underline;
  font-size: 20px;
  line-height: 36px;
  width: 75%;
  margin-top: 9px;
  font-family: ${(props) => props.theme.fonts.Lato};
  font-weight: 700;
  text-underline-position: under;

  &:link {
    color: ${(props) => props.theme.colors.appLink};
  }
  &:visited {
    color: ${(props) => props.theme.colors.appLink};
  }
  &:hover {
    color: ${(props) => props.theme.colors.appLink};
  }
  &:active {
    color: ${(props) => props.theme.colors.appLink};
  }
`;
