import styled from "styled-components";

import { Link } from "react-scroll";
import bannerImage from "../../assets/Home/HeroShape.svg";

export const Wrapper = styled.div`
  ${(props) => props.theme.wrapper};

  padding: 4rem 0 5rem;
  @media (max-width: 810px) {
    padding: 2rem 0 5rem;
  }
`;

export const StyledSection = styled.section`
  background-color: ${(props) => props.theme.colors.appDark900};
  /* border-bottom-left-radius: 7rem; */
  /* border-bottom-right-radius: 7rem; */

  /* position: relative; */
  /* background-image: url(${bannerImage});
  background-size: contain; */
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 810px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
`;

export const TextSide = styled.div`
  width: 60%;
  @media (max-width: 810px) {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 700px) {
    width: 92%;
  }
  @media (max-width: 480px) {
    width: 92%;
  }
`;

export const EmailIcon = styled.img`
  margin-left: 8px;
  margin-right: 8px;
`;

export const Title = styled.h2`
  font-family: ${(props) => props.theme.fonts.Roboto};
  font-size: 52px;
  line-height: 130%;
  font-weight: 600;
  width: 75%;
  color: ${(props) => props.theme.colors.appWhite};
  @media (max-width: 810px) {
    font-size: 36px;
    text-align: center;
  }
  @media (max-width: 480px) {
    font-size: 32px;
    text-align: center;
  }
`;

export const SubTitle = styled.p`
  font-family: ${(props) => props.theme.fonts.Roboto};
  font-size: 24px;
  line-height: 1.5;
  font-weight: 400;
  color: #fafafa;
  margin-top: 24px;
  opacity: 0.8;
  width: 75%;

  @media (max-width: 810px) {
    font-size: 20px;
    text-align: center;
  }
  @media (max-width: 480px) {
    font-size: 16px;
    text-align: center;
  }
`;

export const LearnMoreWrapper = styled.div`
  ${(props) => props.theme.wrapper};
  position: absolute;
  bottom: 2em;
  left: 41.5vw;

  @media (max-width: 600px) {
    left: 38.5vw;
    bottom: 1.5em;
  }
`;

export const LearnMoreImg = styled.img`
  width: 12vw;

  animation: spin 7s infinite alternate ease-in;
  @keyframes spin {
    from {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 600px) {
    width: 16vw;
  }
`;

export const HeroImageCutWrapper = styled.div`
  position: relative;
  margin-top: -2px;
  justify-content: center;
  align-items: center;
`;
export const HeroImageCut = styled.img`
  width: 100%;
`;

export const InputOuter = styled.div`
  display: flex;
  margin-top: 24px;
  margin-bottom: 16px;
  background-color: ${(props) => props.theme.colors.appWhite};
  border-radius: 100px;
  padding: 8px;
  justify-content: space-between;
  width: 80%;

  @media (max-width: 810px) {
    width: 80%;
  }
  @media (max-width: 700px) {
    width: 92%;
  }
  @media (max-width: 480px) {
    width: 92%;
  }

  // width: 40%;
`;

export const InputWrapper = styled.input`
  color: ${(props) => props.theme.colors.appDark600};
  font-family: ${(props) => props.theme.fonts.Roboto};
  border-radius: 100px;
  font-size: 16px;
  line-height: 1.5;
  width: 100%;

  border-style: none;
  height: 60px;
  ::placeholder {
    font-family: ${(props) => props.theme.fonts.Roboto};
  }

  &:focus {
    outline: none;
  }
`;

export const SubscribeButton = styled.button`
  all: unset;
  position: relative;
  background-color: ${(props) => props.theme.colors.appYellow};
  border-radius: 100px;
  /* width: 20%; */
  padding: 16px 24px;
  width: 53%;

  font-family: ${(props) => props.theme.fonts.Roboto};
  font-size: 18px;
  line-height: 1.5;
  /* identical to box height */
  text-align: center;
  cursor: pointer;
  border: none;

  @media (max-width: 810px) {
    font-size: 12px;
    padding: 8px 12px;
  }
  @media (max-width: 480px) {
    font-size: 12px;
    padding: 8px 12px;
  }
`;

export const LabelText = styled.h4`
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${(props) => props.theme.colors.appLightDark25};

  @media (max-width: 810px) {
    font-size: 14px;
    text-align: center;
  }
  @media (max-width: 480px) {
    font-size: 12px;
    text-align: center;
  }
`;

export const SpiralWrapper = styled.div`
  position: absolute;
  transform: translate(-10px, -220px);
  @media (max-width: 1024px) {
    transform: translate(-30px, -250px);
  }
  @media (max-width: 810px) {
    transform: translate(-20px, -125px);
  }
  @media (max-width: 600px) {
    transform: translate(-50px, -150px);
  }
  @media (max-width: 450px) {
    transform: translate(-70px, -150px);
  }
`;

export const HeroImageWrapper = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  margin-top: 200px;

  @media (max-width: 1024px) {
    margin-top: 200px;
  }
  @media (max-width: 810px) {
    margin-top: 100px;
  }

  @media (max-width: 810px) {
    width: 60%;
  }
  @media (max-width: 480px) {
    width: 60%;
  }
`;

export const HeroSpiral = styled.img`
  width: 55%;

  animation: rotate3danim 6s linear;
  @keyframes rotate3danim {
    to {
      /* transform: rotate3d(1, 0, 0, 720deg); */
      transform: rotate3d(1, 0, 0, 1turn);
    }
  }

  /* 
  animation: rotate 4s linear infinite;
  @keyframes rotate {
    0% {
      transform: rotateX(90deg);
    }
    25% {
      transform: rotatey(180deg);
    }
    50% {
      transform: rotatez(270deg);
    }
    75% {
      transform: rotatey(360deg);
    }
    75% {
      transform: rotatex(0deg);
    } 

  }*/

  margin-top: 5%;
`;

export const HeroImage = styled.img`
  object-fit: contain;
  height: auto;
  width: 100%;
  transform: translateY(30px);
  @media (max-width: 1024px) {
    margin-top: -30px;
    margin-bottom: 10px;
  }
  @media (max-width: 810px) {
    margin-top: 52px;
    margin-bottom: 10px;
  }
  @media (max-width: 480px) {
    margin-top: 52px;
    margin-bottom: 10px;
  }
`;

export const TextSection = styled.h2`
  font-family: ${(props) => props.theme.fonts.Poppins};
  font-weight: 600;
  color: ${(props) => props.theme.colors.appWhite};
  line-height: 84px;
  font-size: 78px;
  text-align: center;
  margin-top: -120px;

  .people {
    color: ${(props) => props.theme.colors.appWhite};
    /* display: inline-block; */
  }

  .ideas {
    color: ${(props) => props.theme.colors.appWhite};
    /* display: inline-block; */
  }

  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    /* margin-top: 100px; */

    .ideas {
    }
    .people {
    }
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 52px;
  @media (max-width: 480px) {
    margin-top: 16px;
  }
  @media (max-width: 810px) {
    justify-content: center;
  }
`;

export const LogoWrapper = styled.div`
  padding: 10px 20px;
  padding-bottom: 48px;
  @media (max-width: 810px) {
    padding: 4px 8px;
    margin: 0 auto;
    margin-bottom: 4px;
  }
`;

export const LogoDpiit = styled.img`
  width: 200px;
  @media (max-width: 500px) {
    width: 160px;
  }
`;

export const LogoStartup = styled.img`
  width: 150px;
  @media (max-width: 500px) {
    width: 120px;
  }
`;

export const SeeMoreContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 108px;
  text-align: center;
`;

export const SeeMore = styled.h4`
  font-size: 20px;
  line-height: 36px;
  font-family: ${(props) => props.theme.fonts.Poppins};
  font-weight: 400;
  color: ${(props) => props.theme.colors.appWhite};
`;

export const SeeMoreIcon = styled.img``;

export const SeeMoreWrapper = styled(Link)`
  &:hover {
    text-decoration: none;
  }
  cursor: pointer;
`;

export const FirstLine = styled.h1``;
export const SecondLine = styled.h1``;
