import styled from "styled-components";

import careersBg from "../../assets/careers/careersBg.jpg";

export const Wrapper = styled.div`
  ${props => props.theme.wrapper};
`;

export const CareerTopSection = styled.section`
  background-image: url(${careersBg});
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 102px;
  padding-bottom: 234px;
  position: relative;
  @media (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: 135px;
  }
`;

export const Title = styled.h2`
  font-family: ${props => props.theme.fonts.Lato};
  color: ${props => props.theme.colors.appWhite};
  font-weight: 700;
  text-align: center;
  font-size: 56px;
  line-height: 84px;
  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 54px;
  }
`;

export const TopImageWrapper = styled.div`
  /* z-index: 1; */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -66px;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const TopImage = styled.img`
  width: 404px;
  height: auto;
  @media (max-width: 768px) {
    width: 70%;
    min-width: 280px;
    height: auto;
  }
`;

export const RowWrapper = styled.section``;

export const SubTitle = styled.h3`
  font-family: ${props => props.theme.fonts.Lato};
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  color: ${props => props.theme.colors.appBlack};
  text-align: center;
  margin-top: 115px;
  margin-bottom: 48px;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 30px;
    line-height: 45px;
  }
`;
