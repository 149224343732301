import React from "react";
import { css, ThemeProvider } from "styled-components";

const theme = {
  colors: {
    // new colors which are common start
    appWhite: "#FFFFFF",
    appYellow: "#F4EE72",
    appBlue: "#5165AE",
    appRed: "#EE2924",
    // new colors which are common end

    // colors which are new start
    appLime: "#DDF247",
    appDark900: "#171717",
    appDark800: "#242424",
    appDark700: "#353535",
    appDark600: "#3F3F3F",
    appDark500: "#686868",
    appDark400: "#787878",
    appLightDark300: "#828282",
    appLightDark200: "#989898",
    appLightDark100: "#BEBEBE",
    appLightDark75: "#EEEEEE",
    appLightDark50: "#F3F3F3",
    // appLightDark15 : "",
    appLightDark25: "#FAFAFA",

    // colors which are new end

    appBlack: "#2B2B2B",
    appBlueInner: "#2077A9",
    appLink: "#4567E4",
    appLightGrey: "#656565",
    textLight: "#3F3F3F",
    appGrey1: "#F8F8F8",
    appGrey2: "#E7E7E7",
    appGrey3: "#B8B8B8",
    appGrey4: "#717171",
    appGrey5: "#2B2B2B",
  },
  fonts: {
    Nunito: "Nunito",
    Lato: "Lato",
    Bebas: "Bebas Neue",
    DroidSans: "Droid Sans",
    Poppins: "Poppins",
    Roboto: "Roboto",
  },
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em",
  },
  wrapper: css`
    margin: 0 auto;
    width: 92%;
    max-width: 1440px;
  `,
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
