import styled from "styled-components";

export const Wrapper = styled.div`
  ${props => props.theme.wrapper}
`;

export const StyledSection = styled.section``;

export const ImageWrapper = styled.div`
  margin-top: 200px;
  margin-bottom: 48px;
  text-align: center;
`;

export const ErrorImage = styled.img`
  width: 60%;
  height: auto;
`;

export const ErrorText = styled.h3`
  text-align: center;
  font-family: ${props => props.theme.fonts.Lato};
  font-size: 32px;
  line-height: 48px;
  font-weight: 500;
  margin-bottom: 48px;
`;
