import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

export const StyledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.appYellow};
  ${props =>
    props.secondary &&
    css`
      background-color: ${props => props.theme.colors.appBlue};
      color: ${props => props.theme.colors.appWhite};
      padding: 4px 8px;
    `};
  ${props =>
    props.whiteButton &&
    css`
      color: ${props => props.theme.colors.appBlack};
      background-color: ${props => props.theme.colors.appWhite};
      padding: 4px 8px;
    `}
`;

export const ButtonText = styled.h3`
  font-weight: 600;
`;

export const ButtonIcon = styled.img`
  margin-top: 1px;
`;

export const StyledNavlink = styled(NavLink)`
  color: ${props => props.theme.colors.appBlack};
  font-family: ${props => props.theme.fonts.Lato};
  font-size: 20px;
  line-height: 36px;
  ${props =>
    props.secondary &&
    css`
      font-size: 16px;
      line-height: 24px;
    `}
  margin: 0;
  display: inline-block;
  width: 100%;
  &:hover {
    color: black;
    text-decoration: none;
  }
  ${props =>
    props.whiteButton &&
    css`
      font-size: 16px;
      line-height: 24px;
    `}
`;
