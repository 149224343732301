import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { StyledSection, Wrapper, CardLinkWrapper } from "./styles";

import startup from "../../assets/Blogs/startup.jpg";

var moment = require("moment");

const BlogsPage = () => {
  const [mediumData, setMediumData] = useState([]);

  useEffect(() => {
    fetch(
      `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@ashu149`
    )
      .then(res => res.json())
      .then(response => {
        setMediumData(response.items);
      })
      .catch(err => console.log(err));
  }, []);

  const finalData = mediumData.slice(0, 6);
  console.log(mediumData);
  return (
    <StyledSection>
      <Wrapper>
        <div id="blog" className="container mt-3">
          <Row>
            {finalData.map(article => (
              <Col md="4" className="mb-3" key={article.guid}>
                <div>
                  <CardLinkWrapper
                    href={article.link}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Card>
                      <Card.Img top width="100%" src={startup} alt="img" />
                      <Card.Body>
                        <Card.Title>{article.title}</Card.Title>
                        {/* <Card.Subtitle
                    dangerouslySetInnerHTML={{ __html: article.content }}
                  /> */}
                        <Card.Subtitle>
                          <div>{article.author}</div>
                          <div>
                            {moment(article.pubDate).format("MMMM Do, YYYY")}
                          </div>
                        </Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </CardLinkWrapper>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Wrapper>
    </StyledSection>
  );
};

export default BlogsPage;
