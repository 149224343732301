import React from "react";

import { Wrapper, Number, Line, SectionName } from "./styles";

const SectionNumber = ({
  number = "01",
  text = "About Us",
  darkBg = false,
}) => {
  return (
    <>
      <Wrapper>
        <Number darkBg={darkBg}>{number}</Number>
        <Line darkBg={darkBg} />
      </Wrapper>
      <SectionName darkBg={darkBg}>{text}</SectionName>
    </>
  );
};

export default SectionNumber;
