import styled from "styled-components";

import InputText from "../../styled-elements/InputText";
import TextArea from "../../styled-elements/TextArea";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 39px;
`;

export const StyledLabel = styled.h3`
  font-family: ${props => props.theme.fonts.Lato};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const StyledInputText = styled.input`
  ${InputText};
  width: 100%;
  height: 48px;
  padding: 12px 16px;
  border: none;
  border-radius: 8px;
  margin-bottom: 33px;
`;
export const StyledTextArea = styled.textarea`
  ${TextArea};
  width: 100%;
  resize: none;
  height: 130px;
  border: none;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 48px;
`;

export const ButtonWrapper = styled.div`
  width: 200px;
  @media (max-width: 768px) {
    margin: 0 auto;
  }
`;
