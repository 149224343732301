import React, { useState } from "react";

const JobContext = React.createContext({
  jobId: "",
  onJobSelect: (jobId, jobTitle) => {}
});

export const JobContextProvider = props => {
  const [jobId, setJobId] = useState("");
  const [jobTitle, setJobTitle] = useState("");

  const jobSelectHandler = (selectedJobId, selectedJobTitle) => {
    setJobId(selectedJobId);
    setJobTitle(selectedJobTitle);
  };

  return (
    <JobContext.Provider
      value={{
        jobId,
        jobTitle,
        onJobSelect: jobSelectHandler
      }}
    >
      {props.children}
    </JobContext.Provider>
  );
};

export default JobContext;
