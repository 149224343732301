import { css } from "styled-components";

const SecondaryButton = css`
  color: ${props => props.theme.colors.appBlue};
  background-color: ${props => props.theme.colors.appWhite};
  font-family: ${props => props.theme.fonts.Lato};
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
`;

export default SecondaryButton;
