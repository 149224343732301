import React from "react";

//importing components
import ApplicationForm from "../../components/ApplicationForm/ApplicationForm";

//importing styles
import {
  CareerTopSection,
  Wrapper,
  Title,
  TopImageWrapper,
  TopImage,
  RowWrapper,
  SubTitle
} from "./styles";

//import assets
import careersTop from "../../assets/careers/careersTop.png";

const ApplyJobPage = () => {
  return (
    <>
      {/* banner starts */}
      <CareerTopSection>
        <Wrapper>
          <Title>CAREERS</Title>
          <TopImageWrapper>
            <TopImage src={careersTop} />
          </TopImageWrapper>
        </Wrapper>
      </CareerTopSection>
      <RowWrapper>
        <Wrapper>
          <SubTitle>JOB APPLICATION</SubTitle>
          <ApplicationForm />
        </Wrapper>
      </RowWrapper>
      {/* banner ends */}
    </>
  );
};

export default ApplyJobPage;
