import React, {useRef} from "react";

// import components
import HeroSpiral from "../../components/HeroSpiral/HeroSpiral";

// import utils
import { useWindowSize } from "../../utils/useWindowSize";

//importing images
import openHand from "../../assets/Home/openHand.webp";
import startUpIndia from "../../assets/Footer/startUpIndia.png";
import dpiitLogo from "../../assets/Footer/dpiit (1).png";
import heroSpiral from "../../assets/Home/handSpiral.svg";
import heroShapeCut from "../../assets/Home/HeroShapeCut.svg";
import heroShape from "../../assets/Home/HeroShape.svg";
import LearnMore from "../../assets/Home/LearnMore.svg";

import {
  StyledSection,
  Wrapper,
  TextSection,
  SeeMoreWrapper,
  FirstLine,
  SecondLine,
  SeeMore,
  SeeMoreIcon,
  SeeMoreContainer,
  TextSide,
  Title,
  SubTitle,
  InputWrapper,
  SubscribeButton,
  LabelText,
  HeroImage,
  InnerWrapper,
  InputOuter,
  ImageWrapper,
  LogoWrapper,
  LogoDpiit,
  LogoStartup,
  EmailIcon,
  HeroImageWrapper,
  SpiralWrapper,
  HeroImageCut,
  HeroImageCutWrapper,
  LearnMoreWrapper,
  LearnMoreImg,
  // HeroSpiral,
} from "./styles";

// import assets
import seeMore from "../../assets/LandingSections/svgSeeMore.svg";
import emailIcon from "../../assets/Home/emailIcon.svg";

const HomePage = () => {
  const [width, height] = useWindowSize();
  const emailInputRef = useRef();

  const submitHandler = event => {
    event.preventDefault();
    const enteredEmail = emailInputRef.current.value;
    emailInputRef.current.value = "";

    var myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("content-type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({emailId: enteredEmail})
    };

    fetch("https://api.kleio.me/api/commonApi/putNewsletterEmail", requestOptions)
    .then((response) => {console.log(response)})
    .then((result) => {})
    .catch((error) => {});
  };

  return (
    <>
      <StyledSection id="/">
        <Wrapper>
          <InnerWrapper>
            <TextSide>
              <Title>Redefining Realities, Where Products Leap Forward</Title>
              <SubTitle>
                From the Whisper of a Thought to the Echo of Innovation, We're
                Crafting the Chronicles of Tomorrow.
              </SubTitle>
              <InputOuter>
                <EmailIcon src={emailIcon} alt="envelope" />
                <InputWrapper placeholder="Enter your email"  ref={emailInputRef}/>
                <SubscribeButton onClick={submitHandler}>
                  Subscribe
                </SubscribeButton>
              </InputOuter>
              <LabelText>
                From our minds to your mails : Join the exclusive mail
              </LabelText>

              <ImageWrapper>
                <LogoWrapper>
                  <LogoDpiit src={dpiitLogo} alt="dpiitLogo" height={"auto"} />
                </LogoWrapper>
                <LogoWrapper>
                  <LogoStartup
                    src={startUpIndia}
                    alt="startUpIndia"
                    height={"auto"}
                  />
                </LogoWrapper>
              </ImageWrapper>
            </TextSide>
            <HeroImageWrapper>
              {/* <HeroSpiral src={heroSpiral} alt="spiral" /> */}
              <SpiralWrapper>
                <HeroSpiral />
              </SpiralWrapper>
              <HeroImage src={openHand} alt="hand" />
            </HeroImageWrapper>
          </InnerWrapper>
        </Wrapper>
      </StyledSection>

      <HeroImageCutWrapper>
        <LearnMoreWrapper width={width}>
          <LearnMoreImg src={LearnMore} width={width} />
        </LearnMoreWrapper>
        <HeroImageCut src={heroShapeCut} />
      </HeroImageCutWrapper>
    </>
  );
};

export default HomePage;
