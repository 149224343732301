import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

// import { Link } from "react-scroll";

export const Wrapper = styled.div`
  ${(props) => props.theme.wrapper};
`;

export const AboveWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.appDark900};
  position: sticky;
  z-index: 2;
  transition: top 0.5s;
  ${(props) =>
    props.visible
      ? css`
          top: 0px;
        `
      : css`
          top: -110%;
        `}
`;

export const LogoStyledLink = styled(NavLink)``;

export const StyledUL = styled.ul`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export const Styledh1 = styled.h1``;
export const StyledItem = styled.li`
  list-style: none;
  ${(props) =>
    props.careers &&
    css`
      position: relative;
    `}
`;

export const HiringWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 100px;
  top: -16px;
  left: 45px;
  @media (max-width: 810px) {
    display: none;
  }
`;

export const HiringTriangle = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-right: 13px solid yellow;
  border-bottom: 10px solid transparent;
  right: 100px;
`;
export const HiringRectangle = styled.div`
  background-color: ${(props) => props.theme.colors.appYellow};
  padding-right: 8px;
  /* position: absolute; */
`;

export const HiringTag = styled.img`
  width: 95px;
  height: auto;
`;

export const HiringText = styled.span`
  font-family: ${(props) => props.theme.fonts.DroidSans};
  color: ${(props) => props.theme.colors.appBlack};
  font-size: 10px;
  line-height: 18px;
  font-weight: 700;
`;

export const StyledNav = styled.nav`
  width: 70%;
  display: flex;
  align-items: center;

  @media (max-width: 810px) {
    ${(props) =>
      props.isOpen
        ? css`
            display: block;
            position: fixed;
            top: 80px;
            bottom: 0;
            left: 0;
            justify-content: center;
          `
        : css`
            display: none;
          `};
    width: 100%;
  }
`;

export const HeaderRight = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 810px) {
    /* width: 0%; */
    ${(props) =>
      props.isOpen
        ? css`
            display: block;
            position: fixed;
            top: 80px;
            bottom: 0;
            left: 0;
          `
        : css`
            display: none;
          `}
  }
`;

export const ContactUsWrapper = styled.div`
  @media (max-width: 810px) {
    display: none;
  }
`;

export const RightLinks = styled.div`
  display: flex;
  flex-direction: row;
  width: 65%;
  justify-content: space-between;

  @media (max-width: 810px) {
    flex-direction: column;
    margin-left: 0px;
    width: 80%;
    height: 32vh;
    background-color: ${(props) => props.theme.colors.appGrey1};
    border-radius: 32px;
    overflow: hidden;
  }
`;

export const StyledHashLink = styled(NavHashLink)`
  text-decoration: none;
  background-color: ${(props) => props.theme.colors.appDark800};
  outline: 0px;
  cursor: pointer;
  position: relative;
  font-size: 18px;
  line-height: 27px;
  transition: 0.7s all;
  &:hover {
    font-weight: 400;
    text-decoration: none;
  }
  font-family: ${(props) => props.theme.fonts.Lato};
  font-style: normal;
  font-weight: 400;
  color: ${(props) => props.theme.colors.appWhite};
  border-radius: 100px;
  padding: 14px 14px;
  margin-top: -7px;

  /* Keeping the media query same as  StyledOutLink to maintain the consistency */
  @media (max-width: 810px) {
    ${(props) =>
      props.isOpen &&
      css`
        padding: 20px;
        text-align: center;
        font-size: 28px;
        line-height: 1.5rem;
        color: ${(props) => props.theme.colors.appBlack};
        width: 100%;
        background-color: ${(props) => props.theme.colors.appGrey1};
      `};
  }
`;

export const StyledLink = styled(NavHashLink)`
  text-decoration: none;
  outline: 0px;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  line-height: 21px;
  transition: 0.7s all;
  &:hover {
    font-weight: 400;
    text-decoration: none;
    color: ${(props) => props.theme.colors.appWhite};
  }
  font-family: ${(props) => props.theme.fonts.Lato};
  font-style: normal;
  font-weight: 400;
  color: ${(props) => props.theme.colors.appGrey3};

  /* Keeping the media query same as  Styled Link to maintain the consistency */
  @media (max-width: 810px) {
    ${(props) =>
      props.isOpen &&
      css`
        padding: 8px;
        text-align: center;
        font-size: 18px;
        line-height: 1.5;
        color: ${(props) => props.theme.colors.appBlack};
        width: 100%;
        background-color: ${(props) => props.theme.colors.appGrey1};
      `};
  }
`;

export const LogoWrapper = styled.div`
  padding: 21px;
  padding-left: 20px;
  :hover {
    transform: scale(0.8, 0.8);
  }
`;

export const StyledOutLink = styled(NavLink)`
  text-decoration: none;
  outline: 0px;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  line-height: 21px;
  transition: 0.7s all;
  &:hover {
    font-weight: 400;
    text-decoration: none;
    color: ${(props) => props.theme.colors.appWhite};
  }
  font-family: ${(props) => props.theme.fonts.Lato};
  font-style: normal;
  font-weight: 400;
  color: ${(props) => props.theme.colors.appGrey3};

  /* Keeping the media query same as  Styled Link to maintain the consistency */
  @media (max-width: 810px) {
    ${(props) =>
      props.isOpen &&
      css`
        padding: 8px;
        text-align: center;
        font-size: 18px;
        line-height: 1.5;
        color: ${(props) => props.theme.colors.appBlack};
        width: 100%;
        background-color: ${(props) => props.theme.colors.appGrey1};
      `};
  }
`;

export const StyledHeader = styled.header`
  display: flex;
  max-height: 80px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 810px) {
    width: 95%;
    max-height: 10vh;
    z-index: 100;
  }
`;

export const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 20px 15px;
  @media (max-width: 810px) {
    display: flex;
  }
`;
