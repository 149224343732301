import React from "react";

import {
  InvestorsTopSection,
  Wrapper,
  Title,
  TopImageWrapper,
  TopImage,
  RowWrapper,
  SubTitle,
  Description,
  // Investors middle section
  InvestorsMiddleSection,
  CardWrapper,
  TopLine,
  BottomLine
} from "./styles";

//import assets
import investorsTop from "../../assets/investors/investorsTop.png";

const InvestorsPage = () => {
  return (
    <>
      {/* banner starts */}
      <InvestorsTopSection>
        <Wrapper>
          <Title>INVESTORS</Title>
          <TopImageWrapper>
            <TopImage src={investorsTop} />
          </TopImageWrapper>
        </Wrapper>
      </InvestorsTopSection>
      {/* banner ends */}
      {/* Row 1 starts */}
      <RowWrapper>
        <Wrapper>
          <SubTitle>WHY INVEST IN KLEIO?</SubTitle>
          <Description>
            Kleio Innovation Labs is a Startup India recognised organisation
            that believes having a young and goal-oriented team would lead to
            high growth opportunities, innovative products, and quick turnaround
            times. We are constantly striving to improve in order to provide
            consumers with relevant products.
          </Description>
        </Wrapper>
      </RowWrapper>
      {/* Row 1 ends */}
      {/* Row 2 starts */}
      <InvestorsMiddleSection>
        <Wrapper>
          <CardWrapper>
            <TopLine>We are opening our funding round soon! </TopLine>
            <BottomLine>All potential investors are welcome</BottomLine>
          </CardWrapper>
        </Wrapper>
      </InvestorsMiddleSection>
      {/* Row 2 ends */}
    </>
  );
};

export default InvestorsPage;
