import React from "react";
import { Link, useHistory } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css";

//importing components
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import SectionNumber from "../../components/SectionNumber/SectionNumber";

//importing styles
import {
  Title,
  StyledSection,
  Wrapper,
  ContentWrapper,
  TextSide,
  SubTitle,
  JobSide,
  Jobs,
  JobCard,
  JobContent,
  AvatarImageWrapper,
  AvatarImage,
  JobTitle,
  JobType,
  ApplyTextWrapper,
  ApplyText,
  ArrowImage,
  ButtonWrapper,
  TopText,
  TopTitle,
  CareersDesc,
  ViewAllButton,
  JobWrapper,
  TopRow,
  TopRowLeftText,
  TopRowRightText,
  BottomRow,
  BottomItem,
  BottomItemText,
  JobDivider,
  ItemImage,
  KnowMoreWrapper,
  KnowMoreIcon,
} from "./styles";

// import data
import jobs from "../../data/jobListings";

// import assets
import avatar from "../../assets/OurStory/avatar.svg";
import Location from "../../assets/careers/Location.svg";
import Time from "../../assets/careers/Time.svg";
import Experience from "../../assets/careers/Experience.svg";
import knowMore from "../../assets/careers/knowMore.svg";

const CareersPage = () => {
  const history = useHistory();

  const EachJob = ({ jobId, jobTitle, location, jobType, experience }) => {
    return (
      <>
        <TopRow>
          <TopRowLeftText>{jobTitle}</TopRowLeftText>
          <KnowMoreWrapper onClick={() => history.push(`/careers/${jobId}`)}>
            <TopRowRightText>Know More</TopRowRightText>
            <KnowMoreIcon src={knowMore} alt="arrow" />
          </KnowMoreWrapper>
        </TopRow>
        <BottomRow>
          <BottomItem>
            <ItemImage src={Location} />
            {/* Icon */}
            <BottomItemText>{location}</BottomItemText>
          </BottomItem>

          <BottomItem>
            <ItemImage src={Time} />
            {/* Icon */}
            <BottomItemText>{jobType}</BottomItemText>
          </BottomItem>

          <BottomItem>
            <ItemImage src={Experience} />
            {/* Icon */}
            <BottomItemText>{experience}</BottomItemText>
          </BottomItem>
        </BottomRow>

        <JobDivider />
      </>
    );
  };

  return (
    <StyledSection id="careers">
      <Wrapper>
        <ScrollAnimation animateIn="animate__zoomIn" animatePreScroll={false}>
          <ContentWrapper>
            <TextSide>
              <SectionNumber number="04" text="Careers" darkBg={true} />
              <TopTitle>Open Positions</TopTitle>
              <CareersDesc>
                Working at Kleio innovation labs goes far beyond just having a
                job to make ends meet. With a job at our company, you have
                exciting new possibilities to follow your curiosity wherever it
                takes you.
              </CareersDesc>
              <ViewAllButton
                onClick={() => {
                  history.push("/careers");
                }}
              >
                View all open positions
              </ViewAllButton>
            </TextSide>
            <div style={{ width: "10%" }} />
            <JobSide>
              <JobWrapper>
                {jobs.map((job) => (
                  <EachJob
                    jobId={job.jobId}
                    jobTitle={job.jobTitle}
                    location={job.Location}
                    jobType={job.jobType}
                    experience={job.Experience}
                  />
                ))}
              </JobWrapper>
            </JobSide>
          </ContentWrapper>
        </ScrollAnimation>
      </Wrapper>
    </StyledSection>
  );
};

export default CareersPage;
