import React from "react";
import { useHistory } from "react-router-dom";
//importing components

import jobs from "../../data/jobListings";

//importing styles
import {
  Wrapper,
  AboutContainer,
  TitleTop,
  Caption,
  WhiteTopSection,
  OurStoryContainer,
  Text,
  Description,
  JobWrapper,
  TopRow,
  TopRowLeftText,
  TopRowRightText,
  BottomRow,
  BottomItem,
  BottomItemText,
  JobDivider,
  ItemImage,
  KnowMoreWrapper,
  KnowMoreIcon,
} from "./styles";

//import assets

import Location from "../../assets/careers/Location.svg";
import Time from "../../assets/careers/Time.svg";
import Experience from "../../assets/careers/Experience.svg";
import knowMore from "../../assets/careers/knowMoreBlack.svg";

const CareersNewPage = () => {
  const history = useHistory();

  const EachJob = ({ jobId, jobTitle, location, jobType, experience }) => {
    return (
      <>
        <TopRow>
          <TopRowLeftText>{jobTitle}</TopRowLeftText>
          <KnowMoreWrapper onClick={() => history.push(`/careers/${jobId}`)}>
            <TopRowRightText>Know More</TopRowRightText>
            <KnowMoreIcon src={knowMore} alt="arrow" />
          </KnowMoreWrapper>
        </TopRow>
        <BottomRow>
          <BottomItem>
            <ItemImage src={Location} />
            {/* Icon */}
            <BottomItemText>{location}</BottomItemText>
          </BottomItem>

          <BottomItem>
            <ItemImage src={Time} />
            {/* Icon */}
            <BottomItemText>{jobType}</BottomItemText>
          </BottomItem>

          <BottomItem>
            <ItemImage src={Experience} />
            {/* Icon */}
            <BottomItemText>{experience}</BottomItemText>
          </BottomItem>
        </BottomRow>

        <JobDivider />
      </>
    );
  };

  return (
    <>
      <AboutContainer>
        <Wrapper>
          <TitleTop>Careers</TitleTop>
          <Caption>Let’s build something awesome together</Caption>
        </Wrapper>
      </AboutContainer>
      <Wrapper>
        <WhiteTopSection>
          <OurStoryContainer>
            <Text>Open Positions</Text>
            <Description>
              Working at Kleio innovation labs goes far beyond just having a job
              to make ends meet. With a job at our company, you have exciting
              new possibilities to follow your curiosity wherever it takes you.
            </Description>
          </OurStoryContainer>
        </WhiteTopSection>

        <JobWrapper>
          {jobs.map((job) => (
            <EachJob
              jobId={job.jobId}
              jobTitle={job.jobTitle}
              location={job.Location}
              jobType={job.jobType}
              experience={job.Experience}
            />
          ))}
        </JobWrapper>
      </Wrapper>
    </>
  );
};

export default CareersNewPage;
