import React from "react";
import Theme from "./Theme";
import GlobalStyle from "./globalStyles";
import { BrowserRouter as Router } from "react-router-dom";

//importing components (header, routes and footer)
import Header from "./components/Header/Header";
import Routes from "./routes";
import Footer from "./components/Footer/Footer";

//for react slick (used in the carousels/sliders)
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { JobContextProvider } from "./store/JobContext";
import ScrollToTop from "./scrollToTop";
import BlogsPage from "./pages/Blogs/BlogsPage";
// This will enable to bring the cursor at top at every load

function App() {
  // const pageViewTracking = (props) => {
  //   let pageView;
  //   const pathname = props.match.path;

  //   if (pathname === "*" ){
  //     pageView = "/not-found"
  //   }
  //   else{
  //     pageView = pathname;
  //   }
  //   ReactGA.pageview(pageView);
  // }
  return (
    <div style={{ overflowX: "hidden" }}>
      <JobContextProvider>
        <Theme>
          <GlobalStyle />
          <Router>
            <ScrollToTop />
            <Header />
            <Routes />
            <Footer />
          </Router>
        </Theme>
      </JobContextProvider>
    </div>
  );
}

export default App;
