import React from "react";
import { useHistory } from "react-router-dom";

//import elements
import Table from "react-bootstrap/Table";

//import styles
import { StyledTableWrapper, Styledtr, Wrapper, StyledSection } from "./styles";

//import data
import jobs from "../../data/jobListings";

const AllJobsList = () => {
  let history = useHistory();

  const jobDescriptionHandler = selectedJobId => {
    history.push(`/careers/${selectedJobId}`);
  };

  const jobItems = jobs.map(job => (
    <Styledtr key={job.jobId} onClick={() => jobDescriptionHandler(job.jobId)}>
      <td>{job.jobId}</td>
      <td>{job.jobTitle}</td>
      <td>{job.Experience}</td>
      <td>{job.Location}</td>
    </Styledtr>
  ));

  return (
    <StyledSection>
      <StyledTableWrapper>
        <Wrapper>
          {/* Table for all jobs */}
          <Table bordered hover variant="dark" responsive>
            <thead>
              <th>JOB ID</th>
              <th>JOB TITLE</th>
              <th>EXPERIENCE</th>
              <th>LOCATION</th>
            </thead>
            <tbody>{jobItems}</tbody>
          </Table>
        </Wrapper>
      </StyledTableWrapper>
    </StyledSection>
  );
};

export default AllJobsList;
