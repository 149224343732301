const jobs = [
  {
    jobId: "FSD02",
    jobType: "Full time",
    jobTitle: "Software Developer",
    Experience: " 0-3 yrs",
    Location: "Mumbai",
    Role: `
    <div>• Building user-friendly iOS and Android apps on React Native platform.</div>
    <div>• Designing state-less components in React Native. State management with any of redux, mobX, or flux.</div>
    <div>• Managing DB with MongoDB</div>
    <div>• Writing modular, reusable, maintainable, well documented and fully tested code.</div>
    <div>• Converting designs to mobile application.</div>
    <div>• Integrating with REST APIs; while collaborating with backend developers.</div>
    <div>• Ensure optimum speed, performance, quality, and responsiveness of applications.</div>
    <div>• Exercising sound judgement when architecting front and back end structures.</div>
`,
  },
  {
    jobId: "CWI01",
    jobType: "Internship",
    jobTitle: "Content writer",
    Experience: "0-1 yrs",
    Location: "Mumbai",
    Role: `
    <div>• Manage social media accounts</div>
    <div>• Manage reporting and growth of each channel</div>
    <div>• Create posts and content for social media</div>
    <div>• Create new strategies for accounts that you are working on</div>
    <div>• Creative writing </div>
    <div>• English proficiency(written)</div>
    `,
  },
  {
    jobId: "UID01",
    jobType: "Full time",
    jobTitle: "Jr UI/UX designer",
    Experience: "0-3 yrs",
    Location: "Mumbai",
    Role: `
    <div>• Creating user-centered designs by understanding business requirements, and user feedback</div>
    <div>• Creating user flows, wireframes, prototypes and mockups</div>
    <div>• Translating requirements into style guides, design systems, design patterns and attractive user interfaces</div>
    <div>• Designing UI elements such as input controls, navigational components and informational components</div>
    <div>• Creating original graphic designs (e.g. images, sketches and tables)</div>
    <div>• Identifying and troubleshooting UX problems (e.g. responsiveness)</div>
    <div>• Collaborating effectively with product, engineering, and management teams</div>
    <div>• Incorporating customer feedback, usage metrics, and usability findings into design in order to enhance user experience</div>
    `,
  },
  {
    jobId: "ORI01",
    jobType: "Internship",
    jobTitle: "Operations Intern",
    Experience: "0-1 yrs",
    Location: "Mumbai",
    Role: `
    <div>• Selected intern's day-to-day responsibilities include working on the daily operations and coordinating & handling the meetings for the same.
    </div>
    `,
  },
  {
    jobId: "SDI01",
    jobType: "Full time",
    jobTitle: "Software Developer Intern",
    Experience: "0-1 yrs",
    Location: "Mumbai",
    Role: `
    <div>• Ability to design and code right solutions starting with well-defined problems</div>
    <div>• Write well-documented, maintainable and clean code.</div>
    <div>• Designing websites using modern framework, React.js</div>
    <div>• Creating servers and databases for functionality using Node.js and MongoDB/SQL</div>
    <div>• Designing and developing REST APIs</div>
    <div>• Seeing through a project from conception to finished product</div>
    `,
  },
];

export default jobs;
