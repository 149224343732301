import React from "react";

// import components
import ContactUsPage from "../../sections/ContactUs/ContactUsPage";

//  import assets
import startUpIndia from "../../assets/Footer/startUpIndia.png";
import dpiitLogo from "../../assets/Footer/dpiit (1).png";
import kleioFooter from "../../assets/images/kleioWhite.png";

// import styles
import {
  StyledFooter,
  TopWrapper,
  Wrapper,
  LogoWrapper,
  Logo,
  LogoText,
  TopRight,
  BulletWrapper,
  // Title,
  SubTitle,
  BottomWrapper,
  BottomRight,
  BottomText,
  SocialLinksWrapper,
  SocialIcon,
  SubFooterWrapper,
  SocialWrapper,
  StyledNavLink,
  Copyright,
  ImageWrapper,
  NavsWrapper,
  FooterDivider,
  BottomLeftWrapper,
  StyledNavLinkBottom,
  SubTitleBottom,
} from "./styles";

// import assets
import facebook from "../../assets/Footer/facebook.svg";
import linkedin from "../../assets/Footer/linkedin.svg";
import twitter from "../../assets/Footer/twitter.svg";
import instagram from "../../assets/Footer/instagram.svg";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <ContactUsPage />

      <StyledFooter>
        <Wrapper>
          <TopWrapper>
            {/* Logo part starts */}
            <NavLink to="/">
            <LogoWrapper>
              <Logo src={kleioFooter} alt="logo" width={100} height={"auto"} />
            </LogoWrapper>
            </NavLink>
            {/* Logo part ends */}

            <NavsWrapper>
              <SubTitle>
                <StyledNavLink to="/#ourstory" smooth>
                  About
                </StyledNavLink>
              </SubTitle>

              <SubTitle>
                <StyledNavLink to="/#products" smooth>
                  Products
                </StyledNavLink>
              </SubTitle>

              <SubTitle>
                <StyledNavLink to="/#careers" smooth>
                  Careers
                </StyledNavLink>
              </SubTitle>

              <SubTitle>
                <StyledNavLink to="/blogs" smooth>
                  Blog
                </StyledNavLink>
              </SubTitle>
            </NavsWrapper>
          </TopWrapper>

          <FooterDivider />
          <BottomWrapper>
            <BottomLeftWrapper>
              <SubTitleBottom>
                <StyledNavLinkBottom to="/PrivacyPolicy" smooth>
                  Privacy Policy
                </StyledNavLinkBottom>
              </SubTitleBottom>

              <SubTitleBottom>
                <StyledNavLinkBottom to="/TermAndCondition" smooth>
                  Terms & Conditions
                </StyledNavLinkBottom>
              </SubTitleBottom>
            </BottomLeftWrapper>
            <Copyright>&copy; 2023 Kleio. All rights reserved.</Copyright>
          </BottomWrapper>
        </Wrapper>
      </StyledFooter>
    </>
  );
};

export default Footer;
