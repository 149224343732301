import styled from "styled-components";
import { HashLink } from "react-router-hash-link";

export const Wrapper = styled.div`
  ${(props) => props.theme.wrapper}
`;

export const StyledSection = styled.section``;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 52px;
  position: relative;
  z-index: 10;
  margin-bottom: -112px;
  // bottom: 20px;
  background-color: ${(props) => props.theme.colors.appWhite};
  border-radius: 50px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const LeftSide = styled.div`
  width: 50%;
  padding: 0px 64px;
  @media (max-width: 900px) {
    width: 100%;
    padding: 4%;
  }
`;

export const RightSide = styled.div`
  width: 50%;
  padding: 0px 64px;
  @media (max-width: 900px) {
    width: 100%;
    padding: 4%;
  }
`;

export const TopTitle = styled.h2`
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  color: ${(props) => props.theme.colors.appDark900};
  margin-bottom: 32px;
  @media (max-width: 900px) {
    font-size: 36px;
  }
  @media (max-width: 500px) {
    font-size: 28px;
  }
`;

export const TopSubTitle = styled.h3`
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${(props) => props.theme.colors.appDark600};
  @media (max-width: 900px) {
    font-size: 16px;
  }
`;

export const SocialLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
`;

export const SocialWrapper = styled.li`
  cursor: pointer;
  list-style-type: none;
`;

export const StyledTextArea = styled.textarea`
  color: #3f3f3f;
  font-family: ${(props) => props.theme.fonts.Roboto};
  width: 100%;
  margin: 5px;
  resize: none;
  border: none;
  border-bottom: 1px solid #5064ad;
  padding: 16px 24px;
  margin: 15px 0px;
  font-size: 16px;
  background-color: ${(props) => props.theme.colors.appLightDark75};

  border-style: none;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.4);
  width: 100%;
  resize: none;
  height: 130px;
  border: none;
  border-radius: 20px;
  margin-top: 4px;

  ::placeholder {
    font-family: ${(props) => props.theme.fonts.Roboto};
  }

  &:focus {
    outline: none;
  }
`;

export const StyledButtonWrapper = styled.div`
  width: 100%;
`;

export const StyledButton = styled.button`
  all: unset;
  margin-top: 48px;
  padding: 16px 0px;
  background-color: ${(props) => props.theme.colors.appDark900};
  border-radius: 100px;
  width: 100%;

  font-family: ${(props) => props.theme.fonts.Roboto};
  font-size: 18px;
  line-height: 150%;
  /* identical to box height */
  text-align: center;
  cursor: pointer;
  border: none;
  color: ${(props) => props.theme.colors.appWhite};
`;

export const StyledNavLink = styled(HashLink)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: rgb(248, 248, 248);
  }
`;

export const SocialIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 24px;
`;

export const FormWrapper = styled.div``;

export const FormRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 48px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const SmallField = styled.div`
  width: 47%;
  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 26px;
  }
`;

export const FormLabel = styled.h3`
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${(props) => props.theme.colors.appDark900};
  margin-bottom: 4px;
  margin-left: 2px;
`;

export const FormInput = styled.input`
  color: ${(props) => props.theme.colors.appDark600};
  font-family: ${(props) => props.theme.fonts.Roboto};
  border-radius: 20px;
  font-size: 16px;
  line-height: 150%;
  background-color: ${(props) => props.theme.colors.appLightDark75};
  padding: 16px;

  border-style: none;
  height: 56px;
  width: 100%;
  ::placeholder {
    font-family: ${(props) => props.theme.fonts.Roboto};
  }

  &:focus {
    outline: none;
  }
`;

export const FormSide = styled.div`
  width: 100%;
`;

export const Title = styled.h2`
  font-family: ${(props) => props.theme.fonts.Lato};
  font-size: 36px;
  line-height: 54px;
  color: ${(props) => props.theme.colors.appBlack};
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    margin-top: 60px;
  }
`;

export const SubTitle = styled.h3`
  color: ${(props) => props.theme.colors.appBlack};
  font-family: ${(props) => props.theme.fonts.Lato};
  font-size: 20px;
  line-height: 36px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-top: 12px;
  }
`;

export const ImageSide = styled.div`
  /* margin-left: 50px; */
  margin-left: -52px;
  z-index: -1;
  margin-top: 75px;
  @media (max-width: 768px) {
    margin-left: 0px;
    margin-bottom: 12px;
    margin-top: 0px;
    text-align: center;
  }
`;

export const ImageStory = styled.img`
  width: 100%;
  height: auto;
  @media (max-width: 768px) {
    width: 50%;
    min-width: 250px;
  }
`;

export const MailText = styled.a`
  font-family: ${(props) => props.theme.fonts.Lato};
  margin-left: 5px;
`;
