import React, { useState, useLayoutEffect } from "react";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

//importing components
//importing styles
import {
  Wrapper,
  AboutContainer,
  StoryTitle,
  StoryImage,
  StyledTimeline,
  StoryDate,
  TitleTop,
  Caption,
  WhiteTopSection,
  OurStoryContainer,
  TextSide,
  DescriptionSide,
  Text,
  Description,
} from "./styles";

// import assets

import kleioLogo from "../../assets/about/KleioLogo.png";
import LongTimelineIconRight from "../../assets/about/longTimelineIconRight.svg";
import TimeLine2 from "../../assets/about/timeline-2.svg";
import TimeLine3 from "../../assets/about/timeline-3.svg";
import TimeLine4 from "../../assets/about/timeline-4.svg";
import TimeLine5 from "../../assets/about/timeline-5.svg";

import startupIndia from "../../assets/Footer/startUpIndia.png";
import fitlog from "../../assets/about/Fitlog.png";
import kpaisaImg from "../../assets/about/kpaisaImg.webp";
import kpaisaLogo from "../../assets/about/kPaisaLogoBlack.png";
import SectionNumber from "../../components/SectionNumber/SectionNumber";

const AboutUsNewPage = () => {
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  const [width, height] = useWindowSize();

  return (
    <>
      <AboutContainer>
        <Wrapper>
          <TitleTop>About Us</TitleTop>
          <Caption>We’re making a new way to do business</Caption>
        </Wrapper>
      </AboutContainer>
      <Wrapper>
        <WhiteTopSection>
          <SectionNumber number="01" text="About Us" />
          <OurStoryContainer>
            <TextSide>
              <Text>Our Story</Text>
            </TextSide>
            <DescriptionSide>
              <Description>
                We all come from diverse backgrounds, but what unites us is our
                passion for innovation and excellence, therefore we began to
                pool all of our ideas and thought processes into one single
                concept, Kleio. We are working nonstop to create a product that
                will undoubtedly make users' lives easier.
              </Description>
            </DescriptionSide>
          </OurStoryContainer>
        </WhiteTopSection>
      </Wrapper>
      <StyledTimeline>
        <VerticalTimeline
          layout="2-columns"
          lineColor="#BEBEBE"
          contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
        >
          <VerticalTimelineElement
            icon={
              <div>
                <img src={LongTimelineIconRight} alt="" />
              </div>
            }
            position="right"
            className="foundation"
          >
            <StoryDate>June 2021</StoryDate>
            <StoryTitle>Laid the foundation of Kleio</StoryTitle>
            <StoryImage src={kleioLogo} />
          </VerticalTimelineElement>
          <VerticalTimelineElement
            icon={
              <div>
                <img
                  src={width > 1169 ? TimeLine2 : LongTimelineIconRight}
                  alt=""
                />
              </div>
            }
            position={width > 1169 ? "left" : "right"}
            className={width > 1169 ? "startup" : "foundation"}
          >
            <StoryDate>June 2021</StoryDate>
            <StoryTitle>Startup India Recognized</StoryTitle>
            <StoryImage src={startupIndia} startupIndia />
          </VerticalTimelineElement>
          <VerticalTimelineElement
            icon={
              <div>
                <img
                  src={width > 1169 ? TimeLine3 : LongTimelineIconRight}
                  alt=""
                />
              </div>
            }
            position="right"
            className={width > 1169 ? "fitlog" : "foundation"}
          >
            <StoryDate>June 2021</StoryDate>
            <StoryTitle>Deployment of FitLog</StoryTitle>
            <StoryImage src={fitlog} startupIndia />
          </VerticalTimelineElement>
          <VerticalTimelineElement
            icon={
              <div>
                <img
                  src={width > 1169 ? TimeLine2 : LongTimelineIconRight}
                  alt=""
                />
              </div>
            }
            position={width > 1169 ? "left" : "right"}
            className={width > 1169 ? "kPaisa" : "foundation"}
          >
            <StoryDate>June 2021</StoryDate>
            <StoryTitle>Design & Development of kPaisa</StoryTitle>
            <StoryImage src={kpaisaImg} kpaisaImg />
          </VerticalTimelineElement>
          <VerticalTimelineElement
            icon={
              <div>
                <img src={LongTimelineIconRight} alt="" />
              </div>
            }
            position="right"
            className="foundation"
          >
            <StoryDate>June 2021</StoryDate>
            <StoryTitle>Deployment of kPaisa</StoryTitle>
            <StoryImage src={kpaisaLogo} startupIndia />
          </VerticalTimelineElement>
        </VerticalTimeline>
      </StyledTimeline>
    </>
  );
};

export default AboutUsNewPage;
