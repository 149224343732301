import styled from "styled-components";

//import assets
import productsBg from "../../assets/Products/productsBg.jpg";

export const Wrapper = styled.div`
  ${props => props.theme.wrapper};
`;

export const Title = styled.h2`
  font-family: ${props => props.theme.fonts.Lato};
  color: ${props => props.theme.colors.appWhite};
  font-weight: 700;
  text-align: center;
  font-size: 56px;
  line-height: 84px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 54px;
    margin-bottom: 25px;
  }
`;

export const ProductContainer = styled.section`
  background-image: url(${productsBg});
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 102px;
  padding-bottom: 234px;
  position: relative;
  @media (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: 135px;
  }
`;

export const TopImageWrapper = styled.div`
  /* z-index: 1; */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -66px;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const TopImage = styled.img`
  width: 400px;
  height: auto;
  @media (max-width: 768px) {
    width: 60%;
    min-width: 240px;
    height: auto;
  }
`;

export const RowWrapper = styled.section``;

export const SubTitle = styled.h3`
  font-family: ${props => props.theme.fonts.Lato};
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  color: ${props => props.theme.colors.appBlack};
  text-align: center;
  margin-top: 100px;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 30px;
    line-height: 45px;
  }
`;

export const ProductsList = styled.div`
  margin-top: 48px;
  padding-bottom: 24px;
`;

export const ProductWrapper = styled.div`
  padding: 28px 24px;
  background-color: #f8f9fe;
  border-radius: 16px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 12px;
  }
`;

export const ProductContent = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ProductLogoWrapper = styled.div`
  @media (max-width: 768px) {
    margin: 0 auto;
    margin-bottom: 12px;
  }
`;

export const ProductLogo = styled.img`
  width: 200px;
  height: auto;
`;

export const ProductRightSide = styled.div`
  margin-left: 50px;
  @media (max-width: 768px) {
    margin-left: 0px;
  }
`;

export const ProductRightTop = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ProductTitle = styled.h3`
  font-family: ${props => props.theme.fonts.Lato};
  font-size: 20px;
  line-height: 36px;
  font-weight: 700;
  color: ${props => props.theme.colors.appBlack};
  @media (max-width: 768px) {
    text-align: center;
    font-size: 20px;
    line-height: 36px;
  }
`;

export const ProductDescription = styled.p`
  font-family: ${props => props.theme.fonts.Lato};
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: ${props => props.theme.colors.textLight};
  margin-top: 12px;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 33px;
  @media (max-width: 768px) {
    margin-left: 0px;
    margin: 0 auto;
  }
`;
export const AppleWrapper = styled.div`
  cursor: pointer;
`;
export const AndroidWrapper = styled.div`
  cursor: pointer;
`;

export const DownloadImg = styled.img`
  /* width: 30px; */
  height: auto;
  /* color: black; */
`;

export const LinkWrapper = styled.a`
  &,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

export const ComingSoonWrapper = styled.div`
  background-color: ${props => props.theme.colors.appBlue};
  padding: 7px 18px;
  border-radius: 8px;
  margin-left: 24px;
  @media (max-width: 768px) {
    margin-left: 0px;
    margin: 0 auto;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ComingSoonText = styled.h4`
  color: ${props => props.theme.colors.appWhite};
  font-family: ${props => props.theme.fonts.Lato};
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
`;
