import React from "react";

//importing styles
import {
  StyledSection,
  Wrapper,
  ErrorImage,
  ImageWrapper,
  ErrorText
} from "./styles";

import errorImage from "../../assets/images/imageError.png";

const ErrorPage = () => {
  return (
    <StyledSection>
      <Wrapper>
        <ImageWrapper>
          <ErrorImage src={errorImage} />
        </ImageWrapper>
        <ErrorText>
          Oops! The page you are looking for doesn’t seem to exist!
        </ErrorText>
      </Wrapper>
    </StyledSection>
  );
};

export default ErrorPage;
