import styled, { css } from "styled-components";

import investorBg from "../../assets/investors/investorsBg.jpg";
import preSeedBg from "../../assets/investors/investorsPreSeed.jpg";

export const Wrapper = styled.div`
  ${props => props.theme.wrapper};
`;

export const InvestorsTopSection = styled.section`
  background-image: url(${investorBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-top: 102px;
  padding-bottom: 234px;
  position: relative;
  @media (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: 135px;
  }
`;

export const Title = styled.h2`
  font-family: ${props => props.theme.fonts.Lato};
  color: ${props => props.theme.colors.appWhite};
  font-weight: 700;
  text-align: center;
  font-size: 56px;
  line-height: 84px;
  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 54px;
  }
`;

export const TopImageWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -66px;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const TopImage = styled.img`
  width: 300px;
  height: auto;
  @media (max-width: 768px) {
    width: 50%;
    min-width: 200px;
    height: auto;
  }
`;

export const RowWrapper = styled.section``;

export const SubTitle = styled.h3`
  font-family: ${props => props.theme.fonts.Lato};
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
  color: ${props => props.theme.colors.appBlack};
  text-align: center;
  margin-top: 115px;
  ${props =>
    props.second &&
    css`
      margin-top: 80px;
    `}
  @media (max-width: 768px) {
    text-align: center;
    font-size: 30px;
    line-height: 45px;
    ${props =>
      props.second &&
      css`
        margin-top: 40px;
      `}
  }
`;

export const Description = styled.p`
  text-align: center;
  font-family: ${props => props.theme.fonts.Lato};
  font-size: 20px;
  line-height: 36px;
  font-weight: 400;
  color: ${props => props.theme.colors.textLight};
  margin-top: 30px;
  ${props =>
    props.short &&
    css`
      max-width: 560px;
      margin: 0 auto;
      margin-top: 48px;
      margin-bottom: 48px;
    `}
  ${props =>
    props.team &&
    css`
      margin-top: 20px;
    `}
    @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    font-size: 16px;
    line-height: 24px;
    ${props =>
      props.short &&
      css`
        margin: 0 auto;
        margin-top: 24px;
        margin-bottom: 48px;
      `}
  }
`;

export const InvestorsMiddleSection = styled.section`
  background-color: #2f2f2f;
  margin-top: 54px;
  padding: 120px 0;
`;

export const CardWrapper = styled.div`
  padding: 66px 146px;
  background-image: url(${preSeedBg});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 24px;
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    padding: 33px 73px;
  }
  @media (max-width: 306px) {
    padding: 12px 28px;
  }
`;

export const TopLine = styled.h3`
  font-family: ${props => props.theme.fonts.Lato};
  color: ${props => props.theme.colors.appBlue};
  font-weight: 700;
  text-align: center;
  font-size: 48px;
  line-height: 60px;
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 38px;
  }
`;

export const BottomLine = styled.h4`
  font-family: ${props => props.theme.fonts.Lato};
  color: ${props => props.theme.colors.appBlack};
  font-weight: 600;
  text-align: center;
  font-size: 40px;
  line-height: 60px;
  margin-top: 30px;
  @media (max-width: 768px) {
    font-size: 25px;
    line-height: 38px;
    margin-top: 20px;
  }
`;
